import Header from "./Header"
import starrgyapplyservice from '../store/services/statargyapp'
import { useDispatch, useSelector } from 'react-redux';
import * as actions from '../store/actions/Index';
import { useEffect } from 'react';
import FayersAPIlist from '../store/services/fayersapilist'
import { useState } from 'react';
import fyerstatageylistforactvie from "../store/services/fyersstatrgeylist"
import { ToastContainer, toast } from 'react-toastify';
import { Redirect, useHistory } from 'react-router-dom';
import { Form, Input } from "antd";
import { Table, Popconfirm, Button, Popover } from 'antd';
import { Checkbox, Select } from 'antd';
import Getstgallowid from "../store/services/getstgallowid"
import { eachRight } from "lodash";
import { Row, Col } from 'antd'
import { isDisabled } from "@testing-library/user-event/dist/utils";
import { UnorderedListOutlined } from '@ant-design/icons'; // Import the icon
import Getbrokerlist from "../store/services/Getbrokerlist";
import Getactivestg from "../store/services/Getactivestg";
import Getlistforstgplly from "../store/services/Getlistforstgplly";
import Statargyappnew from "../store/services/statargyappnew";
import Getstatargeyallowid from "../store/services/Getstatargeyallowid";
const Stargeyallocation = () => {
    const statargeyapl = useSelector(state => state.statargeyapl)
    const authState = useSelector(state => state.authState)
    const userId = localStorage.getItem('userid');
    const dispatch = useDispatch();

    const [fayersactive, setFayersactive] = useState([])
    const [fayersactivelist, setFayersactivelist] = useState([])

    const [brokerlist, setbrokerlist] = useState([])
    const [stglist, setstglist] = useState([])
    const [showlist, setshowlist] = useState([])

    const [stgalloid, setStgalloid] = useState("")
    const [strid, setStrid] = useState("")


    const [stgallocationapiid, setStgallocationapiid] = useState("")
    const [newObj, setnewObj] = useState([])
    const history = useHistory();

    const [stgmat, setStgamt] = useState("")
    const [stgadjone, setStgadjone] = useState("")
    const [changesamt, setchanesamt] = useState(false)
    const [changesadjon, setchanesadjon] = useState(false)
    const [loading, setLoading] = useState(false);


    const [brokerid, setbrokerid] = useState(0)
    const [statargeyid, setstatargeyid] = useState()
    const [stghallowid, setstghallowid] = useState()
    const [triggerEffect, setTriggerEffect] = useState(false);

    // const statrgeyapp = (event) => {
    //     debugger
    //     setLoading(true);
    //     dispatch(actions.statrgyapplystart());
    //     const uid = userId;
    //     let selectitmes = fayersactivelist.filter(obj => {
    //         debugger

    //         if (obj.active == true && obj.straType !== "" && obj.straAmount !== 0 && obj.maxAmount !== 0) {
    //             return obj;
    //         }
    //         return
    //     });
    //     // for 

    //     //     if (obj.active == true && obj.straType !== "" && obj.straAmount !== 0 && obj.maxAmount !== 0 && obj.straAdj1 !== 0
    //     //         && obj.straAdj2 !== 0 && obj.straBoost1 !== 0 && obj.straBoost2 !== 0 &&
    //     //         obj.straTarget1 !== 0 && obj.straTarget2 !== 0) {
    //     //         return obj;
    //     //     }
    //     //     return
    //     // });

    //     let statargeyapplydata = {
    //         StraAllowId: stgalloid,
    //         UserId: uid,
    //         BrokerId: 1,
    //         ApiId: stgallocationapiid,
    //         Det: selectitmes
    //     }
    //     if (selectitmes.length > 0) {
    //         debugger
    //         starrgyapplyservice.staapply(statargeyapplydata).then((apiRes) => {
    //             dispatch(actions.statrgyapplystart(apiRes));
    //             debugger
    //             if (apiRes.data.isSuccess) {
    //                 debugger
    //                 toast.info("Strategy Allowacation Successfully...", {
    //                     position: "top-center"
    //                 })
    //             }

    //         })
    //             .catch((error) => {
    //                 // dispatch(actions.statrgeyapplyfail(error));
    //                 console.log("faild")

    //                 debugger
    //             });
    //     } else {
    //         toast.error("Please Fill Data Carefully", {
    //             position: "top-center"
    //         })
    //         setLoading(false)
    //     }
    // }


    const statrgeyapp = (event) => {
        debugger
        setLoading(true);
        dispatch(actions.statrgyapplystart());
        const uid = userId;
        let selectitmes = showlist.filter(obj => {
            debugger

            if (obj.active == true && obj.straType !== "" && obj.straAmount !== 0 && obj.maxAmount !== 0 && obj.optionKey !== 0) {
                return obj;
            }
            return
        });
        // for 

        //     if (obj.active == true && obj.straType !== "" && obj.straAmount !== 0 && obj.maxAmount !== 0 && obj.straAdj1 !== 0
        //         && obj.straAdj2 !== 0 && obj.straBoost1 !== 0 && obj.straBoost2 !== 0 &&
        //         obj.straTarget1 !== 0 && obj.straTarget2 !== 0) {
        //         return obj;
        //     }
        //     return
        // });
        debugger
        let statargeyapplydata = {
            StraAllowId: stghallowid,
            UserId: userId,
            BrokerId: brokerid,
            Det: selectitmes
        }
        if (selectitmes.length > 0) {
            debugger
            console.log(statargeyapplydata)
            Statargyappnew.staapplynew(statargeyapplydata).then((apiRes) => {
                dispatch(actions.statrgyapplystart(apiRes));
                debugger
                if (apiRes.data.isSuccess) {
                    debugger
                    toast.info("Strategy Allowacation Successfully...", {
                        position: "top-center"
                    })
                    
                    setTriggerEffect((prev) => !prev);
                }

            })
                .catch((error) => {
                    // dispatch(actions.statrgeyapplyfail(error));
                    console.log("faild")

                    debugger
                });
        } else {
            toast.error("Please Fill Data Carefully", {
                position: "top-center"
            })
            setLoading(false)
        }
    }

    useEffect(() => {
        const uid = userId
        const fid = 0
        FayersAPIlist.fayersacetivelist(uid, fid).then((apiRes) => {
            debugger
            setFayersactive(apiRes.data.data)

            // setEdituseradmin(true)
            //
        })
            .catch((error) => {
                console.log("faild")

            });
    }, [])

    const getapiid = fayersactive.map((values) => {
        return (

            <option value={values.fyersID}>{values.fyersID}</option>
        )
    })



    const UpdateSt = (e, cItem, field) => {
        debugger
        const newState = showlist.map(obj => {
            debugger
            if (cItem.apiId == obj.apiId) {
                let newObj = { ...obj };
                if (field == "active") {
                    newObj[field] = e.target.checked;
                }
                else {

                    newObj[field] = e.target.value;

                }
                return newObj;
            }

            return obj;
        });
        setshowlist(newState);
        console.log(newState)
    }



    const showstgapplylist = (e) => {
        debugger
        let appid = e
        setStgallocationapiid(appid)
        let userid = userId
        let brokerid = brokerid

        fyerstatageylistforactvie.fyersactivelist(userid, brokerid, appid).then((apires) => {
            debugger
            console.log(apires);
            setFayersactivelist(apires.data.data)
        })
            .catch((error) => {
                console.log("faild")

            })


        debugger
        Getstgallowid.getalloid(userid, brokerid, appid).then((apires) => {
            debugger
            setStgalloid(apires.data.data.straAllowId)
        })
            .catch((error) => {
                console.log("faild")
                debugger
                setStgalloid(0)
            })


    }


    useEffect(()=>{

        debugger

        let uid = userId;
        let brokerId = brokerid;
        let stgid = statargeyid;
        // setstatargeyid(e);

        // Fetch the list data
        Getlistforstgplly.getlistforstgaply(uid, brokerId, stgid).then((apires) => {
            debugger
            // Update the showlist state
            setshowlist(apires.data.data);

            // Now transform the data after setshowlist is done
            // const transformedData = apires.data.data.map(item => ({
            //     ApiId: item.apiId,  // Manually setting this to 1 as per your request
            //     StraId: item.straId,  // Manually setting this to 42
            //     StraType: item.straType,  // Manually setting this to "Option"
            //     StraAmount: item.straAmount,  // Manually setting this to 100
            //     MaxAmount: item.straAmount,  // Manually setting this to 100
            //     OptionKey: item.optionKey  // Manually setting this to 2
            // }));

            // console.log(transformedData); // Use transformed data as needed
            // setshowlist(transformedData)
        }).catch((error) => {
            console.log("error");
        });
        debugger
        // Fetch the strategy allow id
        Getstatargeyallowid.getstgallowgationid(uid, brokerId, stgid).then((apires) => {
            debugger
            setstghallowid(apires.data.data.straAllowId);
        }).catch((error) => {
            console.log(error);
            setstghallowid(0);
        })

    },[brokerid,statargeyid,triggerEffect])


    const showlistforstgaply = (e) => {
        debugger
        setstatargeyid(e);

    };




    // const statargeyaplylisy = fayersactivelist.map((itme) => {
    //     debugger

    //     return 
    //         // <tr key={itme.straId}>
    //         //     <th scope="row">{itme.straId}</th>
    //         //     <td><input type="checkbox" className="form-check-input" checked={itme.active} onClick={(e) => { UpdateSt(e, itme, "active") }}></input>  </td>
    //         //     <select className="form-select" aria-label="Default select example" value={itme.straType} onChange={(e) => { UpdateSt(e, itme, "straType") }} style={{ width: 130 }} >
    //         //         <option value="Select type"></option>
    //         //         <option value="Cash">Cash</option>
    //         //         <option value="Option">option</option>
    //         //     </select>
    //         //     <td style={{ width: 150 }}>{itme.straName}</td>
    //         //     <td><input type="text" id="amount" name="Amount" size={10} className="form-control"
    //         //         onChange={(e) => { UpdateSt(e, itme, "straAmount") }} value={itme.straAmount} ></input></td>
    //         //     <td><input type="text" id="Adjustment01" name="Adjustment01" size={10} border-color={"black"} className="form-control"
    //         //         onChange={(e) => { UpdateSt(e, itme, "straAdj1") }} value={itme.straAdj1} ></input></td>
    //         //     <td><input type="text" id="Adjustment02" name="Adjustment02" size={10} className="form-control"
    //         //         onChange={(e) => { UpdateSt(e, itme, "straAdj2") }} value={itme.straAdj2}></input></td>
    //         //     <td><input type="text" id="Boostqty1" name="Boostqty1" size={10} className="form-control"
    //         //         onChange={(e) => { UpdateSt(e, itme, "straBoost1") }} value={itme.straBoost1}></input></td>
    //         //     <td><input type="text" id="Boostqty2" name="Boostqty2" size={10} className="form-control"
    //         //         onChange={(e) => { UpdateSt(e, itme, "straBoost2") }} value={itme.straBoost2}></input></td>
    //         //     <td><input type="text" id="Target1" name="Target1" size={10} className="form-control"
    //         //         onChange={(e) => { UpdateSt(e, itme, "straTarget1") }} value={itme.straTarget1}></input></td>
    //         //     <td><input type="text" id="Target2" name="Target2" size={10} className="form-control"
    //         //         onChange={(e) => { UpdateSt(e, itme, "straTarget2") }} value={itme.straTarget2}></input></td>
    //         // </tr>



    // })



    useEffect(() => {
        let paraams = ""
        Getbrokerlist.getbrokerlist(paraams).then((apires) => {
            setbrokerlist(apires.data.data)

        }).catch((error) => {
            console.log("error")
        })
    })

    useEffect(() => {
        debugger
        let uid = userId
        Getactivestg.getactivestg(uid).then((apires) => {
            debugger
            setstglist(apires.data.data)

        }).catch((error) => {
            console.log("error")
        })

    }, [])

    const brokeridset = (e) => {
        debugger
        setbrokerid(e)

    }



    const colums = [
        {
            title: "Sr.No",
            dataIndex: "apiId",
            align: "center",

        },
        {
            title: "AppName",
            dataIndex: "appName",
            align: "center",

        },
        {
            title: "clientId",
            dataIndex: "clientId",
            align: "center",

        },
        {
            title: "Active",
            dataIndex: "active",
            align: "center",
            render: (_, record) =>
                modifydata.length >= 1 ? (
                    <Checkbox
                        checked={record.active}
                        onClick={(e) => { UpdateSt(e, record, "active") }}></Checkbox>

                ) : null,
        },
        {
            title: "Statargy type",
            dataIndex: "straType",
            align: "center",

            render: (_, record) =>
                modifydata.length >= 1 ? (
                    <select className="form-select" aria-label="Default select example" value={record.straType} onChange={(e) => { UpdateSt(e, record, "straType") }} style={{ width: 130 }} >
                        <option value=""></option>
                        <option value="Cash">Cash</option>
                        <option value="Option">Option</option>
                    </select>
                ) : null,

        },
        {
            title: "OptionKey",
            dataIndex: "OptionKey",
            align: "center",

            render: (_, record) =>
                modifydata.length >= 1 ? (
                    <select className="form-select" aria-label="Default select example" value={record.optionKey} onChange={(e) => { UpdateSt(e, record, "optionKey") }} style={{ width: 130 }} >
                        <option value=""></option>
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                        <option value="5">5</option>
                        <option value="0">0</option>
                        <option value="-5">-5</option>
                        <option value="-4">-4</option>
                        <option value="-3">-3</option>
                        <option value="-2">-2</option>
                        <option value="-1">-1</option>
                    </select>
                ) : null,
        },
        {
            title: "Amount",
            dataIndex: "straAmount",
            align: "center",


            render: (_, record) =>
                modifydata.length >= 1 ? (
                    <Input
                        value={record.straAmount}
                        size="large"
                        onChange={(e) => { UpdateSt(e, record, "straAmount") }}
                    ></Input>

                ) : null,
        },
        {
            title: "Maxamount ",
            dataIndex: "maxAmount",
            align: "center",

            render: (_, record) =>
                modifydata.length >= 1 ? (
                    <Input
                        value={record.maxAmount}
                        onChange={(e) => { UpdateSt(e, record, "maxAmount") }}
                        size="large"
                    ></Input>

                ) : null,
        },
        // {
        //     title: "Adj 01",
        //     dataIndex: "straAdj1",
        //     align: "center",
        //     width:"10%",
        //     render: (_, record) =>
        //         modifydata.length >= 1 ? (
        //             <Input 
        //             value={record.straAdj1}
        //             onChange={(e) => { UpdateSt(e, record, "straAdj1") }}
        //             ></Input>

        //         ) : null,

        // },
        // {
        //     title: "Adj 02",
        //     dataIndex: "straAdj2",
        //     align: "center",
        //     width:"10%",
        //     render: (_, record) =>
        //         modifydata.length >= 1 ? (
        //             <Input 
        //             value={record.straAdj2}
        //             onChange={(e) => { UpdateSt(e, record, "straAdj2") }}
        //             ></Input>

        //         ) : null,

        // },
        // {
        //     title: "BoostQty",
        //     dataIndex: "straBoost1",
        //     align: "center",
        //     width:"10%",
        //     render: (_, record) =>
        //         modifydata.length >= 1 ? (
        //             <Input 
        //             value={record.straBoost1}
        //             onChange={(e) => { UpdateSt(e, record, "straBoost1") }}
        //             ></Input>

        //         ) : null,
        // },

        // {
        //     title: "BoostQty",
        //     dataIndex: "straBoost2",
        //     align: "center",
        //     width:"10%",
        //     render: (_, record) =>
        //         modifydata.length >= 1 ? (
        //             <Input 
        //             value={record.straBoost2}
        //             onChange={(e) => { UpdateSt(e, record, "straBoost2") }}
        //             ></Input>

        //         ) : null,
        // },

        // {
        //     title: "Targetone",
        //     dataIndex: "straTarget1",
        //     align: "center",
        //     width:"10%",
        //     render: (_, record) =>
        //         modifydata.length >= 1 ? (
        //             <Input 
        //             value={record.straTarget1}
        //             onChange={(e) => { UpdateSt(e, record, "straTarget1") }}
        //             ></Input>

        //         ) : null,
        // },

        // {
        //     title: "Targettow",
        //     dataIndex: "straTarget2",
        //     align: "center",
        //     width:"10%",
        //     render: (_, record) =>
        //         modifydata.length >= 1 ? (

        //             <Input 
        //             value={record.straTarget2}
        //             onChange={(e) => { UpdateSt(e, record, "straTarget2") }}
        //             ></Input>
        //         ) : null,
        // },

    ]

    const modifydata = showlist.map(({ ...item }) => ({
        ...item,
        key: item.apiId,
    }))

    const formItemLayout = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 8 },
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 16 },
        },
    };

    return (
        <div>

            <div className="statargeyallocation" style={{ marginTop: "100px" }}>
                <h3 className="mt-5 ml-5" style={{ marginLeft: "14px", color: "#077193" }}>Strategy Apply </h3>
                <hr style={{ color: "red" }} />

                <div className="statageytopoption">
                    <Form {...formItemLayout}>
                        <Row gutter={[16, 16]}>
                            {/* Broker Selection */}
                            <Col xs={24} sm={12} lg={8}>
                                <Form.Item name="Type" label="Broker" className="select-right-align">
                                    <Select size="large" onChange={brokeridset}>
                                        {/* {brokerlist.map(broker => (
                                            <Select.Option key={broker.brokerId} value={broker.brokerId}>{broker.brokerName}</Select.Option>
                                        ))} */}

                                        <Select.Option value={1}>Mt5</Select.Option>
                                    </Select>
                                </Form.Item>
                            </Col>

                            {/* Strategy Selection */}
                            {/* <Col xs={24} sm={12} lg={8}>
                                <Form.Item name="StraId" label="Strategy" className="select-right-align">
                                    <Select onChange={showstgapplylist} size="large">
                                        {fayersactive.map(stg => (
                                            <Select.Option key={stg.fyersID} value={stg.fyersID}>{stg.fyersID}</Select.Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col> */}

                            <Col xs={24} sm={12} lg={8}>
                                <Form.Item name="StraId" label="Strategy" className="select-right-align">
                                    <Select onChange={showlistforstgaply} size="large" disabled={brokerid == 0}>
                                        {stglist.map(stg => (
                                            <Select.Option key={stg.straId} value={stg.straId}>{stg.straName}</Select.Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </div>

                {/* Table Section */}
                <div className="stgallogation">
                    <Table
                        rowClassName={() => "rowClassName1"}
                        columns={colums}
                        dataSource={modifydata}
                        bordered
                        pagination={false}
                        scroll={{ x: '100%' }} // Adds horizontal scroll for smaller screens
                    />
                </div>

                {/* Place Order Button */}
                <div className="text-center">
                    <button type="button" className="col-md-3 col-sm-12 col-xs-12 placeorderbtn mt-5 mb-5 py-2 w-100" onClick={statrgeyapp}>
                        Place Order
                    </button>
                </div>

            </div>


            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="dark"

            />
        </div>
    )
}

export default Stargeyallocation