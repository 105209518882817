import https from "../../utility/api-client";

class Statrgeyapplynew
{
    staapplynew (params)
    {
        return https.post('/Straallow/PostStraAllows',params)
    }
}

export default new Statrgeyapplynew();