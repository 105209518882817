import { Button, Input, Modal } from 'antd';
import { useState } from 'react';
import apicration from '../store/services/apicartionservices'
import { useDispatch, useSelector } from 'react-redux';
import * as action from '../store/actions/Index'
import { Table, Popconfirm, Popover } from 'antd';
import FayersAPIlist from '../store/services/fayersapilist'
import _ from 'lodash';
import Apideletion from '../store/services/fayerdelete'
import { ToastContainer, toast } from 'react-toastify';
import {
    DeleteOutlined,
    EditOutlined,
    ExclamationCircleOutlined
} from "@ant-design/icons";
import Header from './Header'
import { useEffect } from 'react';
import { Form } from 'antd';

const Whatsapppage = () => {
    debugger
    const [adduseradmin, setAdduseradmin] = useState(false);// for add usermodel
    const [edituseradmin, setEdituseradmin] = useState(false); // for edit usermodel
    const [saveapi, setSaveapi] = useState(false)

    const [userfirstname, setUserfirstname] = useState("")
    const [usermidname, setUsermidname] = useState("")
    const [userlastname, setUserlastname] = useState("")
    const [useremail, setUseremail] = useState("")
    const [usermobile, setUsermobile] = useState("")
    const [useraddress, setUseraddress] = useState("")
    const [usergender, setUsergender] = useState("")
    const [userbirthdate, setUserbirthdate] = useState("")
    const [userpassword, setUserpassword] = useState("")
    const [userconfirmpassword, setUserconfirmpassword] = useState("")

    const [fayersactive, setFayersactive] = useState([])
    const authState = useSelector(state => state.authState)
    const apicreate = useSelector(state => state.apicreate);
    const dispatch = useDispatch();
    const uid = authState.userid
    const [isedting, setIsediting] = useState(false)
    const [edtingadminuserlist, setEdtingadminuserlist] = useState(null)
    //for editing user
    const [editfname, setEditfname] = useState("")
    const [editmname, setEditmname] = useState("")
    const [editlname, setEditlname] = useState("")
    const [editemail, setEditemail] = useState("")
    const [editphone, setEditphone] = useState("")
    const [editaddress, setEditaddress] = useState("")
    const [editbirthdate, setEditbirthdate] = useState("")
    const [editpassword, setEditpassword] = useState("")
    const [editconfirmpassword, setEditconfirmpassword] = useState("")


    useEffect(() => {
        debugger
        const uid = authState.userid
        const fid = 0
        FayersAPIlist.fayersacetivelist(uid, fid).then((apiRes) => {
            setFayersactive(apiRes.data.data)
            // setEdituseradmin(true)
        })
            .catch((error) => { 
                console.log("faild")
                debugger
            });
    })

    const apicrating = () => {
        debugger
        dispatch(action.apicreatestart());
        const params = ""
        // const params = { FyersId: 'MHIuYi5zb2Z0', UserId: uid, ClientID: clientid, AppId: appid, SecretId: secrectid, AppName: appname, Pin: pinid };
        apicration.apicre(params).then((apiRes) => {
            debugger
            dispatch(action.apicreatestart(apiRes));
            console.log(apiRes)
            if (apiRes.data.isSuccess) {
                setUserfirstname('')
                setUsermidname(' ')
                setUserlastname('')
                setUseremail(' ')
                setUsermobile(' ')
                setUseraddress('')
                setUserpassword('')
                setUserconfirmpassword('')
                setAdduseradmin(false)
                toast.info("API Has Been Created Sucessfully", {
                    position: "top-center"
                })
            }
        })
            .catch((error) => {
                debugger
                console.log("faild")

            });
    }

    const getfayerslist = (event) => {
        debugger
        const uid = authState.userid
        const fid = 0
        FayersAPIlist.fayersacetivelist(1, fid).then((apiRes) => {
            setFayersactive(apiRes.data.data)
            // setEdituseradmin(true)
        })
            .catch((error) => {
                console.log("faild")
                debugger
            });
    }

    const editshow = (record) => {
        debugger
        setIsediting(true)
        setEdtingadminuserlist({ ...record })
        setEditfname(record.fyersID)
        setEditmname(record.fyersID)
        setEditlname(record.fyersID)
        setEditemail(record.fyersID)
        setEditphone(record.fyersID)
        setEditaddress(record.fyersID)
        setEditbirthdate(record.fyersID)
        setEditpassword(record.fyersID)
        setEditconfirmpassword(record.fyersID)
    }

    const editrecoerd = () => {
        debugger
        dispatch(action.apicreatestart());
        // const params = { fyersId: editfid, UserId: edituserid, ClientID: editclientid, AppId: editappid, SecretId: editsecid, AppName: editappname, Pin: editpin };
        const params = ""
        apicration.apicre(params).then((apiRes) => {
            debugger

            if (apiRes.data.isSuccess == true) {
                getfayerslist()
                setIsediting(false)
                toast.info("API Has Been updated Sucessfully", {
                    position: "top-center"
                })
            }
        })
            .catch((error) => {
                debugger
                console.log("faild")

            });
    }

    const deleteapi = (record) => {
        debugger
        let fyersid = record.fyersID
        let userid = authState.userid
        const params = { fyersId: fyersid, UserId: userid };
        Apideletion.apidelete(params).then((apiRes) => {
            debugger
            if (apiRes.data.isSuccess == true) {
                getfayerslist()
            }

        })

            .catch((error) => {
                debugger
                console.log("faild")

            });
    }
    debugger
    const colums = [
        {
            title: "FyersId",
            dataIndex: "fyersID",
            align: "center",
            defaultSortOrder: 'descend',
            sorter: {
                compare: (a, b) => a.fyersId - b.fyersId,
                multiple: 1,
            }
        },
        {
            title: "ClientId",
            dataIndex: "clientId",
            align: "center",

        },
        {
            title: "AppName",
            dataIndex: "appName",
            align: "center"
        },
        {
            title: "AppName",
            dataIndex: "appName",
            align: "center"
        },
        {
            title: "Edit",
            dataIndex: "action",
            align: "center",
            render: (_, record) => {
                return (
                    <>
                        <Button type='primary' onClick={() => { editshow(record) }}>
                            Edit
                            <EditOutlined />
                        </Button>
                    </>
                )
            }

        },
        {
            title: "Delete",
            dataIndex: "action",
            align: "center",
            render: (_, record) => {
                return (
                    <>
                        <Popconfirm title=" Do you want to Delete this Record..?? (Note: if delete this record then Strategy Allocation record also deleted)"
                            onConfirm={() => { deleteapi(record) }}
                        >
                            <Button danger>
                                Delete
                                <DeleteOutlined />
                            </Button>
                        </Popconfirm>
                    </>
                )
            }

        },

    ]
    debugger
    const modifydata = fayersactive.map(({ ...item }) => ({
        ...item,
        key: item.fyersId,
    }))

    return (
        <div style={{marginTop:"100px"}}>
            <Button type="primary" block size='large' onClick={() => setAdduseradmin(true)}>Create User</Button>
            <Modal
                title="Create User"
                style={{
                    top: 20,
                }}
                open={adduseradmin}
                onOk={() => { apicrating() }}
                okType='primary'
                okText="Save API"
                onCancel={() => setAdduseradmin(false)}
                width={750}
            >
                <Form>
                    <div className="modal-body">
                        <div className='row'>
                            <div className="mb-3 col-md-4">
                                <label htmlFor="apikey" style={{ color: "black", fontWeight: "bold" }}>* Fisrt Name :</label>
                                <input type="text" className="form-control" value={userfirstname}
                                    id="admin_userfname" name="admin_userfname"
                                    onChange={(e) => setUserfirstname(e.target.value)}
                                />
                            </div>
                            <div className="mb-3 col-md-4">
                                <label htmlFor="apikey" style={{ color: "black", fontWeight: "bold" }}>Mid Name :</label>
                                <input type="text" className="form-control"
                                    id="admin_usermidname" name="admin_usermidname" value={usermidname}
                                    onChange={(e) => setUsermidname(e.target.value)}
                                />
                            </div>
                            <div className="mb-3 col-md-4">
                                <label htmlFor="apikey" style={{ color: "black", fontWeight: "bold" }}>Last Name :</label>
                                <input type="text" className="form-control"
                                    id="admin_userlastname" name="admin_userlastname" value={userlastname}
                                    onChange={(e) => setUserlastname(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className='row'>
                            <div className="mb-3 col-md-6">
                                <label htmlFor="apikey" style={{ color: "black", fontWeight: "bold" }}>Email id   :</label>
                                <input type="email" className="form-control"
                                    id="admin_useremail" name="admin_useremail" value={useremail}
                                    onChange={(e) => setUseremail(e.target.value)}
                                />
                            </div>
                            <div className="mb-3 col-md-6 ">
                                <label htmlFor="apikey" style={{ color: "black", fontWeight: "bold" }}>Mobile no   :</label>
                                <input type="number" className="form-control"
                                    id="admin_userMobile" name="admin_userMobile" value={usermobile}
                                    onChange={(e) => setUsermobile(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="mb-3">
                            <label htmlFor="apikey" style={{ color: "black", fontWeight: "bold" }}>Address  :</label>
                            <input type="text" className="form-control"
                                id="admin_userAddress" name="admin_userAddress" value={useraddress}
                                onChange={(e) => setUseraddress(e.target.value)}
                            />
                        </div>
                        <div className='row'>
                        <div className="mb-3 col-md-2">
                                <input type="radio" name="admin_gender" id="admin_userFemale" value={usergender}
                                    onChange={(e) => setUsergender(e.target.value)}></input>
                                <label htmlFor="apikey" style={{ color: "black", fontWeight: "bold" }}>Female</label>
                            </div>
                            <div className="mb-3 col-md-2">
                                <input type="radio" name="admin_gender" id="admin_userFemale" value={usergender}
                                    onChange={(e) => setUsergender(e.target.value)}></input>
                                <label htmlFor="apikey" style={{ color: "black", fontWeight: "bold" }}>Female</label>
                            </div>
                        </div>
                        <div className="mb-3 col-md-3">
                            <label htmlFor="apikey" style={{ color: "black", fontWeight: "bold" }}>Birth Date :</label>
                            <input className='form-control' name="admin_Birthdate" id="admin_Birthdate" type="date" value={userbirthdate}
                                onChange={(e) => setUserbirthdate(e.target.value)}></input>
                        </div>
                        <div className='row'>
                            <div className='mb-3 col-md-6'>
                                <label htmlFor="apikey" style={{ color: "black", fontWeight: "bold" }}>Password  :</label>
                                <input type="text" className="form-control" id="admin_password" name="admin_password" value={userpassword}
                                    onChange={(e) => setUserpassword(e.target.value)} />
                            </div>
                            <div className='mb-3 col-md-6'>
                                <label htmlFor="apikey" style={{ color: "black", fontWeight: "bold" }}>confirmpassword  :</label>
                                <input type="text" className="form-control" id="admin_confirmpassword" name="admin_confirmpassword" value={userconfirmpassword}
                                    onChange={(e) => setUserconfirmpassword(e.target.value)} />
                            </div>
                        </div>
                    </div>
                </Form>
            </Modal>
            <Modal
                title="Edit API"
                centered
                open={edituseradmin}
                onOk={() => getfayerslist()}
                onCancel={() => setEdituseradmin(false)}
                width={800}
            >

            </Modal>
            <Modal
                title="Editing"
                open={isedting}
                onOk={() => editrecoerd()}
                okText="Save Changes"
                onCancel={() => setIsediting(false)}
                width={800}
            >
                <Form>
                    <div className='modal-body'>
                        <div className='row'>
                            <div className="mb-3 col-md-4">
                                <label htmlFor="apikey" style={{ color: "Black", fontWeight: "bold" }}>first name :</label>
                                <Input className="form-control"
                                    name='editclientid' id='editclientid'
                                    value={edtingadminuserlist?.clientId}
                                    onChange={(e) => {
                                        setEdtingadminuserlist(pre => {
                                            debugger
                                            return { ...pre, clientId: setEditfname(e.target.value) }
                                            debugger
                                        })
                                    }}>
                                </Input>
                            </div>
                            <div className="mb-3 col-md-4">
                                <label htmlFor="apikey" style={{ color: "Black", fontWeight: "bold" }}>Midel Name :</label>
                                <Input className="form-control" name='editappname' id='editappname' value={edtingadminuserlist?.appName} onChange={(e) => {
                                    setEdtingadminuserlist(pre => {
                                        return { ...pre, appName: setEditmname(e.target.value) }
                                    })
                                }}>
                                </Input>
                            </div>
                            <div className="mb-3 col-md-4">
                                <label htmlFor="apikey" style={{ color: "Black", fontWeight: "bold" }}>last Name :</label>
                                <Input className="form-control" name='editappname' id='editappname' value={edtingadminuserlist?.appName} onChange={(e) => {
                                    setEdtingadminuserlist(pre => {
                                        return { ...pre, appName: setEditlname(e.target.value) }
                                    })
                                }}>
                                </Input>
                            </div>
                        </div>
                        <div className='row'>
                            <div className="mb-3 col-md-6">
                                <label htmlFor="apikey" style={{ color: "Black", fontWeight: "bold" }}>Email :</label>
                                <Input className="form-control" name='editappname' id='editappname' value={edtingadminuserlist?.appName} onChange={(e) => {
                                    setEdtingadminuserlist(pre => {
                                        return { ...pre, appName: setEditemail(e.target.value) }
                                    })
                                }}>
                                </Input>
                            </div>
                            <div className="mb-3 col-md-6">
                                <label htmlFor="apikey" style={{ color: "Black", fontWeight: "bold" }}>Phone :</label>
                                <Input className="form-control" name='editappname' id='editappname' value={edtingadminuserlist?.appName} onChange={(e) => {
                                    setEdtingadminuserlist(pre => {
                                        return { ...pre, appName: setEditphone(e.target.value) }
                                    })
                                }}>
                                </Input>
                            </div>
                        </div>
                        <div className="mb-3">
                            <label htmlFor="apikey" style={{ color: "Black", fontWeight: "bold" }}>Address :</label>
                            <Input className="form-control" name='editappname' id='editappname' value={edtingadminuserlist?.appName} onChange={(e) => {
                                setEdtingadminuserlist(pre => {
                                    return { ...pre, appName: setEditaddress(e.target.value) }
                                })
                            }}>
                            </Input>
                        </div>
                        <div className="mb-3">
                            <label htmlFor="apikey" style={{ color: "Black", fontWeight: "bold" }}>Birthdate :</label>
                            <Input className="form-control" name='editappname' id='editappname' value={edtingadminuserlist?.appName} onChange={(e) => {
                                setEdtingadminuserlist(pre => {
                                    return { ...pre, appName: setEditbirthdate(e.target.value) }
                                })
                            }}>
                            </Input>
                        </div>
                        <div className='row'>
                            <div className="mb-3 col-md-6">
                                <label htmlFor="apikey" style={{ color: "Black", fontWeight: "bold" }}>Password :</label>
                                <Input className="form-control" name='editappname' id='editappname' value={edtingadminuserlist?.appName} onChange={(e) => {
                                    setEdtingadminuserlist(pre => {
                                        return { ...pre, appName: setEditpassword(e.target.value) }
                                    })
                                }}>
                                </Input>
                            </div>
                            <div className="mb-3 ">
                                <label htmlFor="apikey" style={{ color: "Black", fontWeight: "bold" }}>Confirm Password :</label>
                                <Input className="form-control" name='editappname' id='editappname' value={edtingadminuserlist?.appName} onChange={(e) => {
                                    setEdtingadminuserlist(pre => {
                                        return { ...pre, appName: setEditconfirmpassword(e.target.value) }
                                    })
                                }}>
                                </Input>
                            </div>
                        </div>
                    </div>
                </Form>
            </Modal>
            <Table
                columns={colums}
                dataSource={modifydata}
                bordered
            />
            <ToastContainer
                position="top-left"
                autoClose={1000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />
        </div>
    );
};
export default Whatsapppage;