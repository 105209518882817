
import https from '../../utility/api-client'

class Getstgallowgationid
{
    
   getstgallowgationid(uid,brokoerid,stgid)
    {
        return https.get('Straallow/GetStraH?user_id=' + uid + '&broker_id=' + brokoerid + '&stra_id=' + stgid);

       
        
    }
}

export default new Getstgallowgationid();