import SignIn from './SignIn';
import React from "react"
import Clientsection from './Clientsection';
import { useEffect } from "react";
import { Redirect, useHistory } from 'react-router-dom';
import ImageScroller from './Imagesscroll';

const Index = () => {
debugger



	// If a user hasn't opted in for recuded motion, then we add the animation
	const history = useHistory()

	useEffect(()=>{
		debugger
	 const islogg =	localStorage.getItem('isLoggedIn')

	 if (islogg === 'true') {
		let path = "/Dashboard";
		history.push(path);
	 }


	},[])


	// const history = useHistory();
	// useEffect(() => {

	// 	localStorage.removeItem('userid')
	// 	localStorage.removeItem('username')
	// 	localStorage.removeItem('auth')
	// 	localStorage.removeItem('tstatus')
	// 	localStorage.removeItem('isLoggedIn');
	// }, [])



	return (
		<div>
			{/* Header */}
			<header className="header_wrapper">
				<nav className="navbar navbar-expand-lg fixed-top ">
					<div className="container-fluid">
						<img src={require("../assets/images/Company_logo.png")} height={100} width={100} className="img-fluid" alt="logo"></img>
						<button className="navbar-toggler" type="button"
							data-bs-toggle="collapse"
							data-bs-target="#navbarNav"
							aria-controls="navbarNav"
							aria-expanded="false"
							aria-label="Toggle navigation">
							<span className="navbar-toggler-icon"></span>
						</button>
						<div className="collapse navbar-collapse " id="navbarNav">
							<ul className="navbar-nav menu-navabr-nav fix"  >
								<li className="nav-item">
									<a className="nav-link active" hidden href="#Page2" style={{ color: 'white' }}>Price</a>
								</li>
								<li className="nav-item dropdown">
									<a className="nav-link dropdown-toggle" hidden href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false" style={{ color: 'white' }}>
										All Affilate
									</a>
								</li>
								<li className="nav-item">
									<a className="nav-link" href="#Page4" hidden style={{ color: 'white' }}>Indicator Store</a>
								</li>
								<li className="nav-item">
									<a className="nav-link" href="#Page5" hidden style={{ color: 'white' }}>Syntex</a>
								</li>
								<li className="nav-item">
									<a className="nav-link" href="#Page5" hidden style={{ color: 'white' }}>Symbol</a>
								</li>
								<li className="nav-item">
									<a className="nav-link" href="Index" hidden style={{ color: 'white' }}>videos</a>
								</li>
							</ul>
							<div className='justify-content-left' >
								<ul className="navbar-nav">
									<li className="nav-item">
										<button className="btn btn-primary" style={{ width: '150' }}><a href="/SignIn" style={{ color: 'white' }}>Login Heare</a></button>
									</li>
									<li className="nav-item justify-content-left">
										<button className="btn btn-dark"><a href="/Signup" style={{ color: 'white' }}>Ragister Now</a></button>
									</li>
								</ul>
							</div>
						</div>
					</div>
				</nav>
			</header>
			<section className="banner_wrapper">
				<div className="container">
					<div className="row align-items-center">
						<div className="col-md-6 my-md-0 text-center text-md-start" >
							<p className="banner-subtital">Boost Your Statargy with</p>
							<h1 className="banner-titel">Start <span> Automated </span> Your Strategy or Indicator </h1>
							<div className="col-md-12 my-md-0 ">
								<p style={{ fontSize: 18, color: 'gray' }}>"Auto bots share trading" refers to the use of automated trading bots or algorithms in the stock market or other financial markets to execute buy and sell orders on behalf of traders or investors. These bots are designed to analyze market data, such as price movements, trading volume, and technical indicators, and make trading decisions based on predetermined rules or algorithms.</p>
							</div>

							<div className="bt-ragisterNow py-3">
								<button className="btn btn-primary"><a href="/Sigin">Sign in Now</a></button>
							</div>

						</div>
						<div className="col-md-6 header-img-section">
							<img src={require("../assets/images/Mainbaner-removebg-preview (2).png")} className="img-fluid" height={250} width={250} alt="Mainbaner" />
						</div>

					</div>
				</div>
			</section>



			{/* <div className='container'>
				<marquee width="100%" direction="left" height="100px" scrollamount="30">
					<img src={require("../assets/images/Angelone.jpg")} height={130} alt={"workingpartner"} />
					<img src={require("../assets/images/Fyers.jpg")} height={100} alt={"workingpartner"} />
					<img src={require("../assets/images/Angelone.jpg")} height={130} alt={"workingpartner"} />
					<img src={require("../assets/images/Fyers.jpg")} height={100} alt={"workingpartner"} />
					<img src={require("../assets/images/Angelone.jpg")} height={130} alt={"workingpartner"} />
					<img src={require("../assets/images/Fyers.jpg")} height={100} alt={"workingpartner"} />
					<img src={require("../assets/images/Angelone.jpg")} height={130} alt={"workingpartner"} />
					<img src={require("../assets/images/Fyers.jpg")} height={100} alt={"workingpartner"} />
					<img src={require("../assets/images/Angelone.jpg")} height={130} alt={"workingpartner"} />
					<img src={require("../assets/images/Angelone.jpg")} height={130} alt={"workingpartner"} />
					<img src={require("../assets/images/Fyers.jpg")} height={100} alt={"workingpartner"} />
					<img src={require("../assets/images/Angelone.jpg")} height={130} alt={"workingpartner"} />
					<img src={require("../assets/images/Fyers.jpg")} height={100} alt={"workingpartner"} />
					<img src={require("../assets/images/Angelone.jpg")} height={130} alt={"workingpartner"} />
					<img src={require("../assets/images/Fyers.jpg")} height={100} alt={"workingpartner"} />
					<img src={require("../assets/images/Angelone.jpg")} height={130} alt={"workingpartner"} />
					<img src={require("../assets/images/Fyers.jpg")} height={100} alt={"workingpartner"} />
					<img src={require("../assets/images/Angelone.jpg")} height={130} alt={"workingpartner"} />
				</marquee>
			</div> */}



			{/* NLB ALGO */}

			<div className=" nlb-alogo " >


				<div class="container px-4 companycard py-5">
					<div className='imgscroller py-5'>
						<ImageScroller />
					</div>
					<div class="row gx-5">
						<div class="col">
							<div class="indexcard p-3 border bg-lightbackgroud">
								<img src={require("../assets/images/NBLimg-1-removebg.png")} height={100} />
								<h1 className="numbers">1000</h1>
								<h5 className="nbaltitel">Total Current Users</h5>
								<p className="nabltext">Not Fake Data</p>
							</div>
						</div>
						<div class="col">
							<div class="indexcard p-3 border bg-lightbackgroud">
								<img src={require("../assets/images/NBLimg-1-removebg.png")} height={100} />
								<h1 className="numbers">1000</h1>
								<h5 className="nbaltitel">24 Hours API Requests</h5>
								<p className="nabltext">All Brokers or Exchange Request</p>
							</div>
						</div>
						<div class="col">
							<div class="indexcard  p-3 border bg-lightbackgroud">
								<img src={require("../assets/images/NBLimg-1-removebg.png")} height={100} />
								<h1 className="numbers">1000</h1>
								<h5 className="nbaltitel">Total of User AP</h5>
								<p className="nabltext">In Our Platform</p>
							</div>
						</div>
						<div class="col">
							<div class="indexcard p-3 border bg-lightbackgroud">
								<img src={require("../assets/images/NBLimg-1-removebg.png")} height={100} />
								<h1 className="numbers">1000</h1>
								<h5 className="nbaltitel" >Total of User AP</h5>
								<p className="nabltext">In Our Platform</p>
							</div>
						</div>
					</div>

				</div>

			</div>



			<div className="overview ">
				<div className="container">
					<div className="row">
						<div className="col-md-3 col-lg-3">

						</div>
						<div className="overview-heading col-md-6 col-lg-6 m-b-5">
							<h2 style={{ textAlign: "center", fontSize: "2.5em" }}>Whitetrade Overview</h2>
							<div>
								<p className="overview-para  m-b-5" style={{ fontSize: "1.2em", justifyContent: "left" }}>
									"Whitetrade India biggest API Bridge
									(automated) Platform. You can add API and call
									anything with unique Webhook. Its Web Application
									base on AWS No need to Any Could, VPS and RTP servers. Low latency and smooth experience"
								</p>
							</div>
						</div>
						<div className="col-md-3 col-lg-3">

						</div>
					</div>
				</div>
			</div>

			<div className="Services py-5">
				<div className="container px-4 mt-4">
					<div className="row gx-5">
						<div className="Services-info col-md-4">
							<div>
								<img src={require("../assets/images/Web Software.png")} height={60} className="img-fluid py-3" style={{ marginBottom: "15px" }} />
								<h3 className="Services-info-heading mb-3">Whitetrade Web Software</h3>
								<p className="Services-info-text">
									Whitetrade API Bridge (automated/Algo) Platform base on unique Webhook You can
									Trigger with Tradingview or Chartink
									Platform and If You Developer when you Trigger API anywhere
									Like Excel, Telegram, amibroker and more.
								</p>
							</div>
						</div>
						<div className="Services-info col-md-4 ">
							<div>
								<img src={require("../assets/images/Indicators.png")} height={60} className="img-fluid py-3" style={{ marginBottom: "15px" }} />
								<h3 className="Services-info-heading" >Free Custom Indicators</h3>
								<p className="Services-info-text">
									Whitetrade provides Free Advance Tradingview Indicator it's Totally free to use first you automate
									the Indicator with Virtual Trade and enhance the filter according to your experience and IQ level
								</p>
							</div>
						</div>
						<div className="Services-info col-md-4">
							<div>
								<img src={require("../assets/images/Brokers.png")} className='img-fluid py-3' style={{ marginBottom: "15px" }} />
								<h3 className="Services-info-heading" >Whitetrade API and Broker</h3>
								<p className="Services-info-text">
									Whitetrade Supporting these Broker and API Kt, Upstox, Fyers, Alice blue, Binance, Samco, Fxcm,5paise, Angel Broking (Angel One), Tradingview, Alpaca, IIFL, Kotak Securities, BitBns,
									Bitmex, Meta Trader (MT4 OR MT5), Wazirx, Delta Exchange and Comming Soon More..
								</p>
							</div>
						</div>
					</div>
					<div className="row gx-5" >
						<div className="Services-info col-md-4 " >
							<div>
								<img src={require("../assets/images/Programming-services.png")} height={50} className="img-fluid py-3" style={{ marginBottom: "15px" }} />
								<h3 className="Services-info-heading" >Programming Servies  </h3>
								<p className="Services-info-text">
									We are built or customization According to Client's any Public API Personal and Commercial Use 100%
									satisfaction with and without Source code servers and domain setup included.
								</p>
							</div>
						</div>
						<div className="Services-info col-md-4">
							<div>
								<img src={require("../assets/images/Tvstatargy.png")} height={50} className="img-fluid py-3" style={{ marginBottom: "15px" }} />
								<h3 className="Services-info-heading" >TV Strategy and Indicator</h3>
								<p className="Services-info-text">
									We are built or customization According to Client's TV Strategy and Indicator and alerts function and telegram bot
									Personal and Commercial Use 100% satisfaction with and without Source code.
								</p>
							</div>
						</div>
						<div className="Services-info col-md-4" >
							<div>
								<img src={require("../assets/images/Indicator_market.png")} height={50} className="img-fluid py-3" style={{ marginBottom: "15px" }} />
								<h3 className="Services-info-heading" >Strategy and Indicator market </h3>
								<p className="Services-info-text ">
									Whitetrade Provide Strategy market place Servies where You can Sell you Indicator or Strategy on monthly or
									one-time base subscription in Our Platform for more detail connect here.
								</p>
							</div>
						</div>
					</div>
					<div className="row gx-5">
						<div className="Services-info col-md-6">
							<img src={require("../assets/images/Varcthual_trade.jpg")} height={50} className="img-fluid " />
						</div>
						<div className="Services-info col-md-6">
							<div style={{ marginTop: "100px" }}>
								<img src={require("../assets/images/robot.png")} className='img-fluid' height={50} />
								<h3 className="Services-info-heading mt-5" >Virtual Trade with Real Time update fully or Semi-automated.</h3>
								<p className="Services-info-text mb-5" style={{ fontSize: "1.3rem" }}>
									Whitetrade API Bridge provides free virtual trade with Tradingview or
									Chartink screener and Option paper trade it's 100% Free no hidden charge and
									fully automated. Sign up On Whitetrade.com and Enjoy virtual trading with
									automation and grow your Learning or accuracy and build your confidence no limitation.
								</p>
							</div>
						</div>
					</div>
					<div className="row gx-5">
						<div className="Services-info col-md-6" >
							<img src={require("../assets/images/overviewimg.png")} height={50} className="img-fluid" />
							<h3 className="Services-info-heading" >Auto-Strike Price in Option Indian API Bridge.</h3>
							<p className="Services-info-text mb-5">
								Whitetrade lets you choose the auto Strike Price in Options it's a powerful feature in the NFO segment,
								you can easily automate
								ATM or OTM and full freedom or control, which makes your option easier and more profitable.
							</p>
							<img src={require("../assets/images/overviewimg.png")} height={50} className="img-fluid" />
							<h3 className="Services-info-heading" >Multi Account or API Management Feature with Low latency.</h3>
							<p className="Services-info-text mb-5">
								Multi-Account or API Management awesome feature if you are pro, you can manage your family,
								friend or Client's account in the same time no limitation and multi-API and Account with
								Whitetrade. This Feature and Increase Your Client Base Its Best Opportunity for You If You Have Experience
								and High IQ Level When You Can Do It and prove yourself community.
							</p>
							<img src={require("../assets/images/overviewimg.png")} height={50} className="img-fluid" />
							<h3 className="Services-info-heading" >Chartink Scanner</h3>
							<p className="Services-info-text">
								We are supporting chartink scanner it's the most powerful full web application
								if you want to Trigger API with chartink scanner it's so easy with our WhiteTrade platform
								easy to set up and no need to VPS or cloud shut down your system and enjoy your Algo with Whitetrade.
							</p>
						</div>
						<div className="Services-info col-md-6" >
							<img src={require("../assets/images/hand-drawn.png")} height={600} className="img-fluid" />
						</div>
					</div>
				</div>
			</div>

			{/* clients section */}
			<Clientsection />
			{/* <div className="container">
					<div className="row">
						<div className="col-md-12">
							<img src={require("../assets/images/Joincomunity.jpg")} height={250} />
						</div>
					</div>
				</div> */}


			<div className="modern-footer">
				<div className="container-fluid">
					<div className="row">
						{/* About Us Section */}
						<div className="col-md-3">
							<h5 className="footer-heading">About Us - Whitetrade</h5>
							<p className="footer-text">
								Whitetrade is a web-based application that works with a unique Webhook.
								You can call and trigger Public API methods. We provide programming tools
								and a marketplace for everyone, even without coding knowledge.
							</p>
						</div>
						{/* More Links Section */}
						<div className="col-md-3">
							<h5 className="footer-heading">More Links</h5>
							<ul className="footer-links">
								<li><a href="./">Community</a></li>
								<li><a href="./">YouTube Channel</a></li>
								<li><a href="./">Indicator Request</a></li>
								<li><a href="./">Resources</a></li>
							</ul>
						</div>
						{/* Pages Section */}
						<div className="col-md-3">
							<h5 className="footer-heading">Pages</h5>
							<ul className="footer-links">
								<li><a href="./TermsAndConditions">Terms & Condition</a></li>
								<li><a href="./PrivacyPolicy">Privacy Policy</a></li>
								<li><a href="./CancellationRefund">Cancellation & Refund</a></li>
								<li><a href="./ContactUs">Conatct us</a></li>
							</ul>
						</div>
						{/* Contact Information Section */}
						<div className="col-md-3">
							<h5 className="footer-heading">Contact Us</h5>
							<ul className="footer-links">
								<li><i className="fas fa-users"></i> <a href="./">Whitetrade Group</a></li>
								<li><i className="fas fa-video"></i> <a href="./">Whitetrade Channel</a></li>
								<li><i className="fab fa-whatsapp"></i> <a href="./">Chat on WhatsApp</a></li>
								<li><i className="fas fa-phone"></i> <a href="./">1800 833 7894</a></li>
								<li><i className="fas fa-phone"></i> <a href="./">9873948912</a></li>
								<li><i className="fab fa-whatsapp"></i> <a href="./">+91 8181811433</a></li>
							</ul>
						</div>
					</div>
					<hr className="footer-divider" />
					{/* Disclaimer Section */}
					<div className="row">
						<div className="col-md-12">
							<p className="footer-disclaimer">
								<strong>Disclaimer:</strong> Futures, stocks, and options trading involves substantial risk of loss and is not suitable for every investor or trader. Whitetrade.com provides only an automation trading tool strategy marketplace, not buy/sell signals or recommendations. Use trading strategies at your own risk.
							</p>
						</div>
					</div>
				</div>
			</div>

		</div>
	)
}

export default Index