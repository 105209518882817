import React, { useContext, useState } from 'react';
import { Home } from '@mui/icons-material';
import './Dashboard.css'; // Custom styles
import { BrowserRouter, Route, Router, Link } from 'react-router-dom'
import Header from './Header';
import logo from "../assets/images/comapny.png"
import { Grid, Container } from '@mui/material';
import Button from '@mui/material/Button';
import { MdOutlineMenu } from "react-icons/md";
import { MdOutlineMenuOpen } from "react-icons/md";
import { MdOutlineLightMode, MdMenuOpen } from "react-icons/md";
import { IoWalletOutline } from "react-icons/io5";
import { IoHomeOutline } from "react-icons/io5";
import { IoIosNotificationsOutline } from "react-icons/io";
import Serchbox from './Serchbox';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import PersonAdd from '@mui/icons-material/PersonAdd';
import Settings from '@mui/icons-material/Settings';
import Logout from '@mui/icons-material/Logout';
import Avatar from '@mui/material/Avatar';
import { Redirect, useHistory } from 'react-router-dom';
import  {Mycontext} from '../hoc/App'
import context from 'react-bootstrap/esm/AccordionContext';
import { MdOutlineDarkMode } from "react-icons/md";
const Headerdashbord = ({ setIsLoggedIn }) => {
	const [anchorEl, setAnchorEl] = useState()
	const [isopnenoificationdropo, setisopnenoificationdropo] = useState(false)
	const openMyacc = Boolean(anchorEl);
	const openMynotification = Boolean(isopnenoificationdropo);
	const history = useHistory();
	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};


	const 	handleClose = () => {
		debugger
		setAnchorEl(null);

	};

	const handellogout = () => {
		setAnchorEl(null);
		localStorage.removeItem('userid')
		localStorage.removeItem('username')
		localStorage.removeItem('auth')
		localStorage.removeItem('tstatus')
		localStorage.removeItem('useremail')
		localStorage.removeItem('isLoggedIn'); // Remove login state from localStorage
		history.push('/'); // Redirect to SignIn page
	}


	const myaccount = () => {
		let path = "/";
		history.push(path);
	}

	const handleCloseMynotificationdrop = () => {
		setisopnenoificationdropo(false)

	}

	const handleOpenMynotificationdrop = () => {
		setisopnenoificationdropo(true)

	}

	const context = useContext(Mycontext)

	let username = localStorage.getItem('username')
	let useremail = localStorage.getItem('useremail')

	return (
		<header className='d-flex align-items-center'>

			<div className="container-fluid w-100">
				<div className="row d-flex align-items-center w-100 ">
					<div className="col-sm-2  part1" >
						<a className='d-flex align-items-center logo'>
							{/* <img src={logo} alt="Logo"/> */}
							{/* <span className='ml-2'>RB Softwares</span> */}
						</a>
					</div>

					<div className="col-sm-3 d-flex align-items-center res-hide">
						<Button className='rounded-circle mr-3' onClick={() => context.setistoogelsidebar(!context.istoogelsidebar)}>
							{
								context.istoogelsidebar === false ? <MdMenuOpen /> : <MdOutlineMenu />
							}

						</Button>
						<Serchbox />
					</div>

					<div className="col-sm-7 ml-3 d-flex align-items-center justify-content-end">
						{/* <Tooltip title={context.themmode === true ? "Change to Dark mode" : "Change to Light mode"} arrow> */}
							<Button className='herdercontent rounded-circle mr-3' onClick={() => context.setthemmode(!context.themmode)}>
								{
									context.themmode === true ? <MdOutlineDarkMode /> : <MdOutlineLightMode />
								}

							</Button>
						{/* </Tooltip> */}

						<div className='dropdownWrapper position-relative'>
						<Button className='rounded-circle mr-3' onClick={handleOpenMynotificationdrop}><IoIosNotificationsOutline />
							</Button>
							<Menu
								anchorEl={isopnenoificationdropo}
								className='notification'
								id="notification"
								open={openMynotification}
								onClose={handleCloseMynotificationdrop}
								onClick={handleCloseMynotificationdrop}
								slotProps={{
									paper: {
										elevation: 0,
										sx: {
											overflow: 'visible',
											filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
											mt: 6.5,
											ml:-18,
											'& .MuiAvatar-root': {
												width: 32,
												height: 32,
												ml: -0.8,
												mr: 5,
											},
											'&::before': {
												content: '""',
												display: 'block',
												position: 'absolute',
												top: 0,
												right: 50,
												width: 10,
												height: 10,
												bgcolor: 'background.paper',
												transform: 'translateY(-50%) rotate(45deg)',
												zIndex: 0,
											},
										},
									},
								}}
								transformOrigin={{ horizontal: 'right', vertical: 'top' }}
								anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
							>
								<MenuItem>
									<ListItemIcon>
										<PersonAdd fontSize="small" />
									</ListItemIcon>
									My Noification
								</MenuItem>
								<MenuItem>
									<ListItemIcon>
										<Settings fontSize="small" />
									</ListItemIcon>
									Change Password
								</MenuItem>
								<MenuItem onClick={handleClose}>
									<ListItemIcon>
										<Logout fontSize="small" />
									</ListItemIcon>
									Logout
								</MenuItem>
							</Menu>
						</div>

					
						<div className="mobiletoogel">
							<Button className='rounded-circle mr-3' onClick={() => context.setisshow(!context.isshow)}>

								<MdOutlineMenu />


							</Button>
						</div>
						<Button className='myacc d-flex align-items-cente ml-3'
							onClick={handleClick}
						>

							<div className='userImg'>
								<span className='rounded-circle'>
									<img src={require("../assets/images/user.jpg")} />
								</span>
							</div>
							<div className='userinfo'>
								<h4>{username}</h4>
								<p className='mb-0 useremail'>{useremail}</p>
							</div>


						</Button>
						<Menu
							anchorEl={anchorEl}
							id="account-menu"
							open={openMyacc}
								onClose={handleClose}
								onClick={handleClose}
							slotProps={{
								paper: {
									elevation: 0,
									sx: {
										overflow: 'visible',
										filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
										mt: 1.5,
										'& .MuiAvatar-root': {
											width: 32,
											height: 32,
											ml: -0.5,
											mr: 1,
										},
										'&::before': {
											content: '""',
											display: 'block',
											position: 'absolute',
											top: 0,
											right: 14,
											width: 10,
											height: 10,
											bgcolor: 'background.paper',
											transform: 'translateY(-50%) rotate(45deg)',
											zIndex: 0,
										},
									},
								},
							}}
							transformOrigin={{ horizontal: 'right', vertical: 'top' }}
							anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
						>
							<MenuItem>
								<ListItemIcon>
									<PersonAdd fontSize="small" />
								</ListItemIcon>
								My Account
							</MenuItem>
							<MenuItem>
								<ListItemIcon>
									<Settings fontSize="small" />
								</ListItemIcon>
								Change Password
							</MenuItem>
							<MenuItem onClick={handellogout}>
								<ListItemIcon>
									<Logout fontSize="small" />
								</ListItemIcon>
								Logout
							</MenuItem>
						</Menu>


					</div>

				</div>

			</div>
		</header>

	)

}

export default Headerdashbord