import https from '../../utility/api-client'

class Getactivestg
{
    
    getactivestg(userid)
    {
        return https.get('/Straallow/GetActiveStra?user_id='+ userid);
    }
}

export default new Getactivestg();