import React from 'react';
import { Container, Grid, Card, CardContent, Typography, Divider, Link } from '@mui/material';

const TermsAndConditions = () => {
    return (
        <div style={{ marginTop: "120px" }}>
            <Container maxWidth="md" sx={{ mt: 4, mb: 4 }}>
                <Card elevation={3} sx={{ borderRadius: '16px', padding: 3 }}>
                    <CardContent>
                        {/* Main Title */}
                        <Typography variant="h4" component="h1" gutterBottom sx={{ fontWeight: 'bold' }}>
                            Terms and Conditions
                        </Typography>

                        {/* Introduction Section */}
                        <Divider sx={{ mb: 3 }} />
                        <Typography variant="h5" component="h1" gutterBottom sx={{ fontWeight: 'bold' }}>
                            Welcome to software.asiabullalgo.in
                        </Typography>
                        <Divider sx={{ mt: 3, mb: 3 }} />
                        <Typography variant="h6" gutterBottom sx={{ color: '#3f51b5' }}>
                            Introduction
                        </Typography>
                        <Typography variant="body1" paragraph sx={{ color: '#555' }}>
                            Welcome to software.asiabullalgo.in, a platform offering algorithmic trading services.
                            By using our website, you agree to abide by these terms and conditions.The terms ‘we’, ‘us’, and ‘our’ refer to software.asiabullalgo.in, and ‘you’ refers to the user or visitor of our website.
                        </Typography>
                        {/* License Section */}
                        <Divider sx={{ mt: 3, mb: 3 }} />
                        <Typography variant="h6" gutterBottom sx={{ color: '#3f51b5' }}>
                            Eligibility
                        </Typography>
                        <Typography variant="body1" paragraph sx={{ color: '#555' }}>
                            Our services are intended for individuals who are 18 years or older and legally capable of entering into contracts. By using [Website Name], you represent that you meet these requirements.

                        </Typography>

                        <ul>
                            <li>You Must 18 years old</li>
                        </ul>

                        {/* Payment Terms Section */}
                        <Divider sx={{ mt: 3, mb: 3 }} />
                        <Typography variant="h6" gutterBottom sx={{ color: '#3f51b5' }}>
                            Use of Service
                        </Typography>
                        <Typography variant="body1" paragraph sx={{ color: '#555' }}>
                            You agree to use [Website Name] only for lawful purposes and in accordance with these terms. You are prohibited from using the service to engage in any fraudulent or illegal activities, including market manipulation or unauthorized access to other users' accounts.
                        </Typography>
                        <ul>
                            <li>Misuse of trading tools.</li>
                            <li>Manipulation of market data.</li>
                            <li>Engaging in illegal trading practices</li>
                        </ul>

                        {/* User Comments Section */}
                        <Divider sx={{ mt: 3, mb: 3 }} />
                        <Typography variant="h6" gutterBottom sx={{ color: '#3f51b5' }}>
                            Account and Security
                        </Typography>
                        <Typography variant="body1" paragraph sx={{ color: '#555' }}>
                            You are responsible for maintaining the confidentiality of your account information, including your username and password. Any unauthorized access or use of your account must be reported to us immediately. We are not liable for any loss or damage arising from unauthorized use of your account.
                        </Typography>


                        <Divider sx={{ mt: 3, mb: 3 }} />
                        <Typography variant="h6" gutterBottom sx={{ color: '#3f51b5' }}>
                            Algo Trading Disclaimer
                        </Typography>
                        <Typography variant="body1" paragraph sx={{ color: '#555' }}>
                            Algorithmic trading involves inherent risks. Past performance is not indicative of future results, and you should not rely solely on the information provided through our service. We are not responsible for any financial loss you may incur while using [Website Name].
                        </Typography>

                        <Divider sx={{ mt: 3, mb: 3 }} />
                        <Typography variant="h6" gutterBottom sx={{ color: '#3f51b5' }}>
                        User Conduct
                        </Typography>
                        <Typography variant="body1" paragraph sx={{ color: '#555' }}>
                        You agree not to engage in any behavior that could harm the functionality of [Website Name], including uploading malicious code, exploiting vulnerabilities, or misusing trading tools. Any content you submit must comply with applicable laws and not infringe upon the rights of others.

                        </Typography>


                        <Divider sx={{ mt: 3, mb: 3 }} />
                        <Typography variant="h6" gutterBottom sx={{ color: '#3f51b5' }}>
                        Fees and Payments
                        </Typography>
                        <Typography variant="body1" paragraph sx={{ color: '#555' }}>
                        Access to certain features of [Website Name] requires a subscription. Fees are outlined on our pricing page and are subject to change. Payments are due at the beginning of the subscription period, and refunds are only provided under specific conditions as outlined in our refund policy.
                        for more information visit our <Link>Cancellation and Refund</Link>Page
                        </Typography>


                        
                        <Divider sx={{ mt: 3, mb: 3 }} />
                        <Typography variant="h6" gutterBottom sx={{ color: '#3f51b5' }}>
                        Intellectual Property
                        </Typography>
                        <Typography variant="body1" paragraph sx={{ color: '#555' }}>
                        All content on [Website Name], including but not limited to software, designs, algorithms, and text, is the exclusive property of [Website Name]. You may not reproduce, distribute, or modify any part of our content without our prior written consent.

                        </Typography>

                        {/* Footer or Links to Policies */}
                        <Divider sx={{ mt: 4 }} />
                        <Typography variant="body2" color="textSecondary" align="center" sx={{ mt: 3 }}>
                            By continuing to use our website, you agree to these terms and conditions.
                        </Typography>
                    </CardContent>
                </Card>
            </Container>
        </div>
    );
};

export default TermsAndConditions;
