import Header from "./Header"


const Telegramchanel = () => {
	
	return (
		
		<div style={{marginTop:"100px"}}>
			<h1>Telegramchanel</h1>
		</div>

)

}

export default Telegramchanel