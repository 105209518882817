
import https from '../../utility/api-client'

class Getlistforstgaply
{
    
    getlistforstgaply(userid,brokerid,stgid)
    {
        return https.get('/Straallow/GetFillDetails?user_id='+ userid +'&broker_id='+brokerid+'&stra_id='+stgid);
    }
}

export default new Getlistforstgaply();