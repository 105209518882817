import { Table } from 'antd';
import { useEffect, useState } from 'react';
// In the fifth row, other columns are merged into first column
// by setting it's colSpan to be 0
import Gethookdataservice from "../store/services/gethookdataservice"
import { useDispatch, useSelector } from 'react-redux';
import Loaidngcom from "./loadingcomponent"
import { Button, Input, Modal } from 'antd';
const Hookdata = () => {
	const [data, setData] = useState([])
	const authState = useSelector(state => state.authState)

	const userId = localStorage.getItem('userid');
	const username = localStorage.getItem('username')
	const userauth = localStorage.getItem('auth')

	const hookdatafetch = () => {
		  
		let userid = userId
		Gethookdataservice.gethookdatas(userid).then((apires) => {
			<Loaidngcom />
			  
			console.log(apires);
			setData(apires.data.data)
		})
			.catch((error) => {
				console.log("faild")
				  
			})
	}


	useEffect(() => {
		hookdatafetch()
	}, [])


		;


	const columns = [
		{
			title: 'HookId',
			dataIndex: 'hookId',
			width: "10%",
			align: "center",
			sorter: (a, b) => a.hookId - b.hookId,
		},
		{
			title: 'HookDateTime',
			dataIndex: 'hookDateTime',
			align: "center",
			width: "20%",
			sorter: (a, b) => new Date(a.hookDateTime) - new Date(b.hookDateTime),
		},
		{
			title: 'StraName',
			dataIndex: 'straName',
			align: "center",
			width: "20%",
			sorter: (a, b) => a.straName - b.straName,
		},
		{
			title: 'Ticker',
			dataIndex: 'ticker',
			align: "center",
			width: "10%",
			sorter: (a, b) => a.ticker.localeCompare(b.ticker)
		},
		{
			title: 'Exchange',
			dataIndex: 'exchange',
			align: "center",
			width: "10%",
			sorter: (a, b) => a.exchange - b.exchange,
		},
		{
			title: 'Type',
			dataIndex: 'type',
			align: "center",
			width: "10%",
			sorter: (a, b) => a.type - b.type,
			
		},
		{
			title: 'Price',
			dataIndex: 'price',
			align: "right",
			render: (price) => parseFloat(price).toFixed(2),
			width: "10%",
		},

	];


	return (

		<div className='hookdatadiv'>
			<div className='refreshdata'>
				<Button type='primary' onClick={() => {hookdatafetch()}}> Refresh Data </Button>
			</div>
			<Table
				columns={columns}
				dataSource={data}
				bordered
				scroll={{ x: true}}
	
			>
			</Table>
		</div>



	)

}

export default Hookdata