import { useEffect } from "react"
import Dashapicard from "../store/services/Dashapiworking"
import { useState } from "react"

import { FaUserCircle } from "react-icons/fa";
import { HiDotsVertical } from "react-icons/hi";
import { IoMdTrendingUp } from "react-icons/io";
import { RiStockFill } from "react-icons/ri";
import { IoStatsChartSharp } from "react-icons/io5";
const Apiworkingcard = () => {


    const [dashapicount, setdashapicount] = useState(0)


    useEffect(() => {
        debugger
        let userId = localStorage.getItem('userid');
        Dashapicard.dashapiworking(userId).then((apires) => {
            debugger
            setdashapicount(apires.data.data.apiCount)

        })
            .catch((error) => {
                console.log("faild")
            })
    }, [])

    return (


        <div className="dashbordbox col-md-8" style={{ backgroundImage: 'linear-gradient(to right, #1da256, #48d483)', position: 'relative' }}>
            <div className="d-flex w-100">
                <div className="col1">
                    <h4 className="text-white mb-0">API Active</h4>
                    <span className="text-white">{dashapicount}</span>
                </div>
                <div className="icon-container">
                    <span className="icon"><FaUserCircle /></span>
                </div>
            </div>
            <span className="continerbackground"><IoStatsChartSharp /></span>
        </div>

    )

}

export default Apiworkingcard