import https from '../../utility/api-client'

class Getplanaddonstg
{
    
    getplnforstg(id)
    {
        return https.get('/Inv/GetPlanidForStra?user_id='+id);
    }
}

export default new Getplanaddonstg();