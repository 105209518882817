import https from '../../utility/api-client'

class Paymentorder
{
    
    payorder(params)
    {
        return https.post('/Raz/order',params);
    }
}

export default new Paymentorder();