import React, { useEffect, useState } from 'react';
import { CircularProgress, Box, Typography } from '@mui/material';
import Getsoftremaindays from '../store/services/Getsoftremaindays';
const MembershipExpiryPieChart = () => {
	//{ daysRemaining = 90, totalDays = 100 }
	const [progress, setProgress] = useState(0);
	// const percentage = (daysRemaining / totalDays) * 100;

	const [daysRemaining,setdaysRemaining] = useState()
	const [totalDays,settotalDays] = useState()
	const [percentage,setpercentage] = useState()
	const [plantype,setplantype] = useState()

	useEffect(() => {
		debugger
		let uid = localStorage.getItem('userid'); // Get user ID from local storage
		Getsoftremaindays.getsoftremaindays(uid)
			.then((apires) => {
				debugger
				// Update state with the data from the API response
				const totalInvDays = apires.data.data.iplanDays;
				const remainingDays = apires.data.data.invtRemainDays;
				setdaysRemaining(remainingDays);
				settotalDays(totalInvDays);
				setplantype(apires.data.data.iplanName)
			})
			.catch((error) => {
				console.log("error", error);
			});
	}, []); // Only run this effect on mount
	
	// A separate useEffect to calculate the progress when daysRemaining or totalDays change
	useEffect(() => {
		if (totalDays > 0) {
			setProgress((daysRemaining / totalDays) * 100); // Calculate progress percentage
		}
	}, [daysRemaining, totalDays,[]]); // Re-run this effect whenever these state values change
	



	useEffect(() => {
		const timer = setInterval(() => {
			setProgress((oldProgress) => {
				// If progress reaches the target percentage, stop the timer
				const diff = percentage - oldProgress;
				if (oldProgress >= percentage) {
					clearInterval(timer);
					return percentage;
				}
				return Math.min(oldProgress + 1, percentage); // Increment progress
			});
		}, 10); // Adjust speed of the animation

		return () => {
			clearInterval(timer); // Cleanup the interval on component unmount
		};
	}, [percentage]);

	return (
		<Box position="relative" display="inline-flex">
			<CircularProgress
				variant="determinate"
				value={progress} // Use the animated progress state
				size={200}
				thickness={6}

				sx={{
					color: percentage < 20 ? '#ff5722' : '#4caf50', // Custom colors based on remaining days
				}}
			/>
			<Box
				top={0}
				left={0}
				bottom={0}
				right={0}
				position="absolute"
				display="flex"
				alignItems="center"
				justifyContent="center"
			>
				
				<Typography variant="h6" component="div" color="textSecondary">
					{daysRemaining} days left 
				</Typography>
			</Box>
		</Box>
	);
};

export default MembershipExpiryPieChart;
