// import https from '../../utility/api-client'

// class Activemtfiveapiid
// {
    
//     activemtfiveapiid(params)
//     {
//         return https.post('/mt/CreateMtConnection',params);
//     }
// }

// export default new Activemtfiveapiid();






import https from '../../utility/api-client'

class Activemtfiveapiid
{
    
    activemtfiveapiid(params)
    {
        return https.post('/GetUser', params);
    }
}

export default new Activemtfiveapiid();