import https from '../../utility/api-client'

class Fillappapilist
{
    
    filallapilist(userid)
    {
        return https.get('/Fyers/GetAllApiList?UserId='+userid);
    }
}

export default new Fillappapilist();