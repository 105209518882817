import Header from "./Header"
import { Form, Formik, Field, ErrorMessage, useFormik } from 'formik';
import * as Yup from 'yup';
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Statargeyplan from "./Statargeyplan";
import * as actions from '../store/actions/Index'
import { Redirect, useHistory } from 'react-router-dom';
import { Route } from 'react-router';
import Mmermershipplanservives from '../store/services/Membershiplanlistser'
import optianmemplaser from '../store/services/optianmemplaser'
import getplanforaddonstg from '../store/services/getplanforaddonstg'
import TreeList from "../component/Treelist"
import Tree from "../component/Tree";
import Asd from '../component/Whatsapppage';
import TreeView from '@mui/lab/TreeView';
import TreeItem from '@mui/lab/TreeItem';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { Select } from "@mui/material";
import { Container, Row, Tabs, Tab } from 'react-bootstrap'
import { DataGrid } from '@mui/x-data-grid';
import _ from "lodash";
import Paginationpage from '../Admincomponent/pagination'
import ShowUserplan from '../component/ShowoptainPlan'
import MembershipRenewPlan from '../store/services/renewplanser'
import Loaidngcom from "../component/loadingcomponent"
import Perchedmembershipplan from "../store/services/optianmemplaser"
const pageSize = 10;

const MemberShipplan = () => {
	 
	const [currentpage, setCurrentpage] = useState(1)
	const [postperpage, setPostperpage] = useState(10)
	const [order, setOrder] = useState("ASC")
	const [data, setData] = useState([])
	const lastpostindex = currentpage * postperpage
	const firstpostindex = lastpostindex - postperpage
	const updatedonin = useSelector(state => state.updatedonin)
	const srgchkstutesed = useSelector(state => state.srgchkstutesed)
	const [memplanid, setMemplanid] = useState("")
	const [memplanamount, setMemplanmount] = useState("")
	const [membershipcard, setMembershipcard] = useState([])
	const [upadteinvoice, setUpdateinvoice] = useState(false)
	const [isvsiable, setIsvsiable] = useState(false)
	const formik = useFormik({
		initialValues: {
			radio: "",
		},
		validateOnMount: true,
		validationSchema: Yup.object({
			radio: Yup.string()
				.required("This is required"),
		}),
		onSubmit: (values) => {
			console.log(values)
		}
	})

	const memabeshipopt = useSelector(state => state.memabeshipopt)
	const dispatch = useDispatch();
	const history = useHistory();
	const [membershipid, setMembershipid] = useState();
	const [membershipplan, setMembershiplan] = useState()
	const [membershipplananame, setMembershipplanname] = useState()
	const [membershipdays, setMembershipdays] = useState()
	const [addstg, setAddstg] = useState(false)
	const [memberplnlist, setMemberplnlist] = useState([])
	const [membershipoptain, setMembershioptain] = useState([])
	const [statageyoptain, setStatageyoptain] = useState()

	const authState = useSelector(state => state.authState)
	const userId = localStorage.getItem('userid')

	const [mempalnexits, setmempalnexits] = useState(false)
	const [memplan, setmemplan] = useState([])

	useEffect(() => {

		dispatch(actions.getoptainplan())
		let params = null
		Mmermershipplanservives.GetMembershiplist(params).then((apiRes) => {

			<Loaidngcom />

			setMembershipcard(apiRes.data.data)
		})
			.catch((error) => {
				<Loaidngcom />
				console.log("faild")


			})

	}, [])


	useEffect(() => {
		Perchedmembershipplan.optmemrshipplan(userId).then((apiRes) => {
			 
			setmemplan(apiRes.data.data)


		}).catch((error) => {
			console.log(error)
		})

	}, []);


	useEffect(() => {
		const exists = memplan.some(plan => plan.planId !== 9 && plan.planDays > 0);
		setmempalnexits(exists);
	}, [memplan]);

	useEffect(() => {

		dispatch(actions.getmemershipplanlist())
		let uid = userId
		optianmemplaser.optmemrshipplan(uid).then((apiRes) => {
			<Loaidngcom />
			console.log("m-API Respons", apiRes.data.data)
			setMembershioptain(apiRes.data.data)
			console.log("m-state", membershipoptain)




		})
			.catch((error) => {
				<Loaidngcom />
				console.log("faild")
			})

	}, [])

	const currentpost = membershipoptain.slice(firstpostindex, lastpostindex);
	// for Adding new Membership 
	const addmemership = (e, memship) => {
		debugger
		 
		if (e.target.click) {

			setMembershipid(memship.planId)
			setMembershiplan(memship.planPrice)
			setMembershipplanname(memship.planName)
			setMembershipdays(memship.planDays)
			localStorage.setItem('planId', memship.planId);
			localStorage.setItem('planPrice', memship.planPrice);
			localStorage.setItem('planName', memship.planName);
			localStorage.setItem('planDays', memship.planDays);
			localStorage.setItem('planAmt', memship.planPrice);
			let path = "/Statargeyplan";
			history.push(path);
			console.log(membershipid)
			dispatch(actions.membershipoptain(memship.planId, memship.planPrice, memship.planName, memship.planDays))
			dispatch(actions.chekstgsttaues())
			if (upadteinvoice == false) {
				dispatch(actions.updateinvoice(0))
				dispatch(actions.chekstgsttaues())
			}
		}
	}

	const updatemembership = (e, memship) => {

		if (e.target.click) {
			let invoiceid = memship.invId
			dispatch(actions.updateinvoice(memship.invId))
			let path = "/Statargeyplan";
			setUpdateinvoice(true)
			dispatch(actions.chekstgsttaues())
		}
	}

	const renewuserplan = () => {

		dispatch(actions.postrenuvalplan())

		let params = { userid: 1, Invoiceid: 55 }

		MembershipRenewPlan.memshiprenew(params).then((apiRes) => {
			<Loaidngcom />
		})
			.catch((error) => {
				<Loaidngcom />
				console.log("faild")
			})
	}

	const addstatargey = () => {
		dispatch(actions.addonstgmem())
		let uid = userId
		getplanforaddonstg.getplnforstg(uid).then((apiRes) => {
			let planId = apiRes.data.data.planId
			let planAmt = apiRes.data.data.planAmt
			let planName = apiRes.data.data.planName
			let planDays = apiRes.data.data.planDays
			let path = "/Statargeyplan";
			history.push(path);
			dispatch(actions.membershipoptain(planId, planAmt, planName, planDays))
			dispatch(actions.chekstgsttauesaddon())
			dispatch(actions.updateinvoice(0))
		})
			.catch((error) => {
				console.log("faild")
			})
	}

	let str = null;
	const showsstg = () => {
		setIsvsiable(true)
		if (isvsiable) {
			str = <div>
				abc
			</div>
		}
	}


	let membershipopt = membershipoptain.map((item) => {


		return (
			<tr key={item.id}>
				<td>{item.id}</td>
				<td>{item.invDate}</td>
				<td>{item.planName}</td>
				<td>{item.planDays}</td>
				<td>{item.expDate}</td>
				<td>{item.invTotAmt}</td>
				<td>{item.rDays}</td>
				<td>{item.paymentStatu}</td>
				<td><button className="btn btn-success">Renew</button></td>
				<td> <button type="button" disabled={upadteinvoice == true} onClick={(e) => { updatemembership(e, item) }} className="btn btn-success">Update</button></td>
				<td> <button type="button" className="btn btn-primary" onClick={addstatargey}>Add Stg</button></td>
			</tr>
		)

	})


	const sorting = (col) => {
		if (order === "ASC") {
			const sorted = [...membershipoptain].sort((a, b) =>
				a.invId.toLowerCase() > b.invId.toLowerCase() ? 1 : -1
			);
			setData(sorted)
			setOrder("DEC")
		}
		if (order === "DEC") {
			const sorted = [...membershipoptain].sort((a, b) =>
				a.invId.toLowerCase() < b.invId.toLowerCase() ? 1 : -1
			);
			setData(sorted)
			setOrder("ASC")
		}
	}

	let memcard = membershipcard.map((item) => {


		return (
			<div className="col-md-3 col-sm-12  mb-3 mb-sm-0"  >
				<div key={item.id} className="box one" hidden={item.planId === 9}>
					<label id="price"><span><i className="fa fa-inr" aria-hidden="true"></i></span>₹{item.planPrice}</label>
					<div className="top">
						<p id="title"><i className="fa fa-frown-o" aria-hidden="true"></i>{item.planName}</p>
					</div>
					<div className="mid">
						<p>{item.planDays} DAYS</p>
						<p>{item.planAmt}/- Rs</p>
						<p>{item.planDisc} % Discount</p>
					</div>
					{/* <button>checkout now<i className="fa fa-shopping-cart" aria-hidden="true"></i></button> */}
					<button type="button" onClick={(e) => { addmemership(e, item) }} className="btn btn-primary" disabled={mempalnexits}><i className="bi bi-cart2"></i>Buy Plan</button>
				</div>
			</div>
		)
	})




	return (
		<div className="container-fluid" style={{marginTop:"50px"}}>
			<Container className='py-5'>
				<Row className='justify-content-center'>
					<Tabs justify variant='pills' active className="mb-1 p-0">
						<Tab eventKey="tab-1" title="Member Plan" >

							<div className="row mt-5 mb-5">
								{memcard}
								{memberplnlist && (
									<p className="mt-5" style={{color:"red",fontWeight:"bold",backgroundColor:"yellow"}} >
										Note :-If your plan already exists, you cannot buy a new plan; you can only add a strategy using the Edit plan option.
									</p>
								)}
							</div>

						</Tab>

						<Tab eventKey="tab-3" title="Edit Plan">
							<ShowUserplan />
						</Tab>
					</Tabs>
				</Row>



			</Container>
		</div>

	)
}

export default MemberShipplan



