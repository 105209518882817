    import { useEffect } from "react";
import fyerspostservice from "../store/services/fayerspost";
import { ToastContainer, toast } from 'react-toastify';
import Loaidngcom from "../component/loadingcomponent"
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
const Fyerssuccessful = () => {
    
debugger
    useEffect(() => {
        debugger
        const queryparms = new URLSearchParams(window.location.search)
        const s = queryparms.get('s')
        const statevalue = queryparms.get('state')
        const auth_code = queryparms.get('auth_code') 
        const params = {S:s,state:statevalue,AuthCode:auth_code};
        fyerspostservice.fyesrpost(params).then((apires) => {   
            debugger
            <Loaidngcom/>
            toast.info(apires.data.message, {
                position: "top-center"
            })  
                s = "";
                statevalue="";
                auth_code="";
        })
            .catch((error) => {
                console.log("faild")
                debugger
            })
    },[])


    let history = useHistory()
    const closewindow = () => {
        let path = "/Dashboard"
        history.push(path)

    }

    return (
        <>
            
            <div className="fyerssucess" style={{marginTop:"50px"}}>
            <img src={require("../assets/images/ThumbsUp.gif")} height={"350px"}></img>
           
            <h1><p>Now please <span><div ><button
            style={{
                padding:"5px",
                backgroundColor:"rebeccapurple",
                color:"white"

            }}
            onClick={closewindow}
            >Click Heare</button></div></span> </p></h1>
            </div>
            <ToastContainer
                position="top-left"
                autoClose={1000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />
        </>
    )
}

export default Fyerssuccessful;