import https from '../../utility/api-client'

class Getsoftremaindays
{
    
    getsoftremaindays(id)
    {
        return https.get('/Inv/GetSoftRemainDays?UserId='+ id);
       
    }
}

export default new Getsoftremaindays();