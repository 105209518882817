
import https from "../../utility/api-client";

class Dashapiworking
{
    dashapiworking(uesrid)
    {
        return https.get('/Fyers/GetAllApiCount?UserId='+uesrid);
    }
}

export default new Dashapiworking();
