import https from '../../utility/api-client'

class Getbrokerlist
{
    
    getbrokerlist(brokerId)
    {
        return https.get('/Straallow/GetBrokers');
    }
}

export default new Getbrokerlist();