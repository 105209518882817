import React, { createContext, useEffect, useState, useContext } from 'react';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import Sidebar from '../component/sidebar';
import Index from '../component/layout-genral';
import Dashbordmain from '../component/Dashbordmain';
import Dashboard from '../component/Dashbord';
import SignIn from '../component/SignIn';
import MemberShipplan from '../component/MemberShipplan';
import Signup from '../component/Signup';
import Frogotpassword from '../component/Frogotpassword'
import Apiactivaction from '../component/ApiActivction';
import Headerdashbord from '../component/Headerdashbord';
import Statargeyplan from '../component/Statargeyplan';
import Checkout from '../component/Checkout';
import FyersStargeyallocation from '../component/Satargeyallocation_fyers';
import Mt5Stargeyallocation from '../component/Satargeyallocation_mt5';
import Papertrading from '../component/Papertrading';	
import Mangment from '../component/Mangment';
import Trade from '../component/Trade';
import Resorces from "../component/Resorces"
import Telegramchanel from '../component/TalegramChanale';
import Telegramgroup from '../component/TelegramGroup';
import Whatsapppage from '../component/Whatsapppage';
import Videospage from '../component/Videospage';
import Clientlist from '../Admincomponent/Clientlist';
import Changeauth from '../Admincomponent/Changeauth'
import UserInfo from '../Admincomponent/userInformation';
import Tradingviewlist from '../component/tradingview'
import ConvertData from '../Admincomponent/Convertdatas';
import Logout from '../component/Logout'
import TermsAndConditions from '../component/Termsandcondtion';
import CancellationRefund from '../component/Cancelationandrefund';
import PrivacyPolicy from '../component/PrivicyPolicy';
import ContactUs from '../component/Conatctus';
import TelegramAlerts from '../Admincomponent/Telegramalert';
import Invoice from '../component/Invoice';	
import Fyerssuccessful from '../component/fyerssuccessful';
const Mycontext = createContext()
const App = () => {
	// Retrieve the isLoggedIn state from localStorage when the app loads
	const [isLoggedIn, setIsLoggedIn] = useState(() => {
		return localStorage.getItem('isLoggedIn') === 'true'; // Retrieve and parse the login state
	});
	

	// const [isLoggedIn, setIsLoggedIn] = useState(() => {
	// 	// Check if 'isLoggedIn' exists in localStorage and return the value
	// 	return localStorage.getItem('isLoggedIn') === 'true';
	// });

	debugger

	// Set up to clear isLoggedIn only when the user closes the browser or tab
	// useEffect(() => {
	// 	const handleBeforeUnload = (event) => {
	// 		// Check if the user is closing the tab/window, not refreshing the page
	// 		const navigationEntries = performance.getEntriesByType('navigation')[0];
	
	// 		setTimeout(() => {
	// 			// Check if the type of navigation is not a reload
	// 			if (navigationEntries && navigationEntries.type !== 'reload') {
	// 				// This will only run if it's not a page refresh
	// 				localStorage.removeItem('isLoggedIn');
	// 			}
	// 		}, 10); // Add a small delay
	// 	};
	
	// 	// Add the event listener for the beforeunload event
	// 	window.addEventListener('beforeunload', handleBeforeUnload);
	
	// 	// Clean up the event listener when the component unmounts
	// 	return () => {
	// 		window.removeEventListener('beforeunload', handleBeforeUnload);
	// 	};
	// }, []);	
	;

	const handleLogin = (value) => {
		setIsLoggedIn(value);
		localStorage.setItem('isLoggedIn', value); // Persist login state in localStorage
	};
	
	const handleLogout = () => {
		setIsLoggedIn(false);
		localStorage.removeItem('isLoggedIn'); // Clear login state from localStorage on logout
	};

	const context = useContext(Mycontext);
	const [istoogelsidebar, setistoogelsidebar] = useState(false);
	const [isshow, setisshow] = useState(false);
	const [themmode, setthemmode] = useState(true);

	// Handle theme mode persistence
	useEffect(() => {
		if (themmode === true) {
			document.body.classList.remove('dark');
			document.body.classList.add('light');
			localStorage.setItem('themmode', 'light');
		} else {
			document.body.classList.remove('light');
			document.body.classList.add('dark');
			localStorage.setItem('themmode', 'dark');
		}
	}, [themmode]);

	const values = {
		istoogelsidebar,
		setistoogelsidebar,
		themmode,
		setthemmode,
		isshow,
		setisshow,
	};

	return (
		<BrowserRouter>
			<Switch>
				{/* Show Index first */}
				<Route path="/" exact component={Index} />
				<Route path="/Signup" exact component={Signup} />
				<Route path="/Frogotpassword" exact component={Frogotpassword} />
				<Route path="/TermsAndConditions" exact component={TermsAndConditions} />
				<Route path="/CancellationRefund" exact component={CancellationRefund} />
				<Route path="/PrivacyPolicy" exact component={PrivacyPolicy} />
				<Route path="/ContactUs" exact component={ContactUs} />
				
				{/* SignIn route */}
				<Route path="/SignIn">
				{isLoggedIn ? <Redirect to="/Dashboard" /> : <SignIn setIsLoggedIn={handleLogin} />}
				</Route>
				
				{/* Protected Routes */}
				{isLoggedIn && (
					<div>
						<Mycontext.Provider value={values}>
							<Headerdashbord />
							<div className="main d-flex">
								<div className={`sidebarOverlay d-none ${isshow === true ? 'show' : ''}`} onClick={() => { setisshow(false); }}></div>
								<div className={`sidebarwrapper ${istoogelsidebar === true ? 'toggel' : ''} ${isshow === true ? 'open' : ''}`}>
									<Sidebar />
								</div>
								<div className={`content ${istoogelsidebar === true ? 'toggel' : ''}`} onClick={() => context.setisshow(!context.isshow)}>
									<Switch>
										{/* Dashboard routes */}
										<Route path="/Dashboard" exact component={Dashboard} />
										<Route path="/MemberShipplan" exact component={MemberShipplan} />
										<Route path="/Signup" exact component={Signup} />
										<Route path="/Frogotpassword" exact component={Frogotpassword} />
										<Route path="/Apiactivaction" exact component={Apiactivaction} />
										<Route path="/Statargeyplan" exact component={Statargeyplan} />
										<Route path="/Checkout" exact component={Checkout} />
										<Route path="/FyersStargeyallocation" exact component={FyersStargeyallocation} />
										<Route path="/Mt5Stargeyallocation" exact component={Mt5Stargeyallocation} />
										<Route path="/Logout" exact component={Logout} onClick={handleLogout} />
										<Route path="/Papertrading" exact component={Papertrading} />
										<Route path="/Mangment" exact component={Mangment} />
										<Route path="/Trade" exact component={Trade} />
										<Route path="/Resorces" exact component={Resorces} />
										<Route path="/Telegramchanel" exact component={Telegramchanel} />
										<Route path="/Telegramgroup" exact component={Telegramgroup} />
										<Route path="/Whatsapppage" exact component={Whatsapppage} />
										<Route path="/Videospage" exact component={Videospage} />
										<Route path="/Clientlist" exact component={Clientlist} />
										<Route path="/Changeauth" exact component={Changeauth} />
										<Route path="/UserInfo" exact component={UserInfo} />
										<Route path="/Tradingviewlist" exact component={Tradingviewlist} />
										<Route path="/ConvertData" exact component={ConvertData} />
										<Route path="/fyerssuccessful" exact component={Fyerssuccessful} />
										<Route path="/TelegramAlerts" exact component={TelegramAlerts} />
										<Route path="/Invoice" exact component={Invoice} />
										{/* Add more routes as needed */}
									</Switch>
								</div>
							</div>
						</Mycontext.Provider>
					</div>
				)}

				{/* Redirect to SignIn if not logged in */}
				{!isLoggedIn && <Redirect to="/SignIn" />}
			</Switch>
		</BrowserRouter>
	);
};
;

export default App;
export { Mycontext }