import https from '../../utility/api-client'

class FyersPostservices
{
    
    fyesrpost(params)
    {
        return https.post('/Fyers/PostAuthCode', params);
    }
}

export default new FyersPostservices();