import { useEffect } from "react"
import Dashstgcard from "../store/services/Dashstgcard"
import { useState } from "react"
import { FaUserCircle } from "react-icons/fa";
import { HiDotsVertical } from "react-icons/hi";
import { IoMdTrendingUp } from "react-icons/io";
import { MdQueryStats } from "react-icons/md";
const Dashstgactive = () => {
    const [dashstgcount, setDashstgcount] = useState()
    const userId = localStorage.getItem('userid');

    useEffect  (() => {
        Dashstgcard.dashstgworking(userId).then((apires) => {
       
            setDashstgcount(apires.data.data)
        })
            .catch((error) => {
                console.log("Strategy Active faild")
              
            })
    },[])


    return (
        <div className="dashbordbox" style={{ backgroundImage: 'linear-gradient(to right, #c012e2, #eb64fe)', position: 'relative' }}>           
            <div className="d-flex w-100">
                <div className="col1">
                    <h4 className="text-white mb-0">Strategy Active</h4>
                    <span className="text-white">277</span>
                </div>
                <div className="icon-container">
                    <span className="icon"><FaUserCircle /></span>
                </div>
            </div>
            <span className="continerbackground"><MdQueryStats/></span>
        </div>

    )

}

export default Dashstgactive