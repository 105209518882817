import { useState,useEffect } from "react"
import {Switch,Button,Popconfirm,Table} from "antd"

import Fillallapilist from "../store/services/Fillallapilist.js";
import fyersPostdesibalservices from "../store/services/fyerspostdesabale"
import { styled } from "@mui/material";
import { render } from "@testing-library/react";
import Loaidngcom from "./loadingcomponent"
import { ToastContainer, toast } from 'react-toastify';
import Activemt5Apiid from '../store/services/Activemt5Apiid.js';
import axios from 'axios';
const Allapiactive = () => {
    debugger
    const [allapidata,setallapidata] = useState([])
    const userId = localStorage.getItem('userid');
    // {Brokername:"Mt5",fyersid:"1",Appaname:"Bwe123",Action:"Active/Inactive" },
    // {Brokername:"Mt4",fyersid:"2",Appaname:"Bwe13",Action:"Active/Inactive" }
    const [metafiveapiid,setmetafiveapiid] = useState()
    const Metafiveapiactivation = (e) => {

        setmetafiveapiid(e)
        


    }



// let metafiveID = "MTFyLmIuc29mdA=="
// let userid = 8
// const params = { MtId: metafiveID, UserId: userid };



    useEffect(()=>{

		Fillallapilist.filallapilist(userId).then((apires)=>{
			setallapidata(apires.data.data)

		}).catch((error)=>{
			console.log("error")
		})

	},[])

    

    const modifydata = allapidata.map(({ ...item }) => ({
        ...item,
        key: item.mtId,
    }))


    const UpdateSt = (e, cItem, field) => {
        debugger;
        const newState = allapidata.map(obj => {
            if (cItem.id == obj.id) {
                let newObj = { ...obj };
                newObj[field] = e === true ? "true" : "false";
                return newObj;
            }
            return obj;
        });
        
        setallapidata(newState);
        console.log(newState);
        debugger;
    
        if (e !== true) {
            let broker = cItem.broker;
            let id = cItem.id;
            debugger;
            
            // Check if broker is Fyers
            if (broker === "Fyers") {
                debugger
                const params = { Fid: id };
                fyersPostdesibalservices.fyesrpostdisable(params)
                    .then((apiRes) => {
                        <Loaidngcom />; // This won't be executed; consider moving it outside if it's a loader.
                        if (apiRes.data.isSuccess === true) {
                            toast.info("Fyers API has been Inactive", {
                                position: "top-center"
                            });
                        }
                    })
                    .catch((error) => {
                        console.log("Failed to disable Fyers API", error);
                    });
            } else {
                // Logic for non-Fyers brokers
                toast.info("This broker is not Fyers", {
                    position: "top-center"
                });
            }
        } else {
            let appid = cItem.appId;
            let fyersID = cItem.id;
    
            if (cItem.broker === "Fyers") {
                window.location.href = "https://api.fyers.in/api/v2/generate-authcode?client_id=" 
                + appid 
                + "&redirect_uri=http://software.asiabullalgo.in/fyerssuccessful&response_type=code&state=" 
                + fyersID;
            } else {
                // Logic for Metafive broker or other brokers
                debugger
                const metafiveID = cItem.id;
    
                // Call the Metafive API directly
                async function callMetafiveApi(id) {
                    const params = { MtId: id };
                    debugger;
                    try {
                        const response = await fetch('https://pyapi.asiabullalgo.in/mt/CreateMtConnection', {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json', // Correct headers for sending JSON
                                'Accept': 'application/json',       // Expect JSON response
                            },
                            body: JSON.stringify(params),          // Convert the params to JSON string
                        });
    
                        if (!response.ok) {
                            throw new Error(`HTTP error! Status: ${response.status}`);
                        }
    
                        const data = await response.json();        // Parse the response JSON
                        toast.success("Metafive API Active", {
                            position: "top-center"
                        });
                        console.log(data);
                    } catch (error) {
                        toast.error("Metafive API Error", {
                            position: "top-center"
                        });
                        console.log("Error:", error);              // Log the error details
                    }
                }
    
                // Invoke the API call
                callMetafiveApi(metafiveID);
            }
        }
    };
    

    const colums = [
        {
            title: "id",
            dataIndex: "id",
            align: "center",
            defaultSortOrder: 'descend',
            sorter: {
                compare: (a, b) => a.id - b.id,
                multiple: 1,
            }
        },
        {
            title: "Broker",
            dataIndex: "broker",
            align: "center",
            render: (text) => {
                let color = text === "Fyers" ? "blue" : text === "Meta5" ? "red" : "black"; // Default to black for other brokers
                return (
                    <span style={{ color,fontSize:"20px" }}>
                        {text}
                    </span>
                );
            }
        },
        {
            title: "AppsName",
            dataIndex: "appsName",
            align: "center"

        },
        {
            title: "Active/Inactive",
            dataIndex: "status",
            align: "center",
            render: (_, record) => {
                return (
                    <>
                 
                        <Switch checkedChildren="Active" unCheckedChildren="Inactive"
                            checked={(record.status === "false" || record.status === "") ? false :  true}
                            onChange={(e) => {UpdateSt(e, record, "status") }}
                           disabled ={record.status === "true" && record.broker === "Mt5"}
                        />
                    </>
                )
            }

        },




    ]





    return (
        <>
            <div className="allapis">
            {/* <button onClick={callApi}>Check api</button> */}
            <Table
                    columns={colums}
                    dataSource={modifydata}
                    bordered
                    scroll={{ x: true}}
                    pagination={{ pageSize: 5 }}
                    className="custom-table" 
                />

                <div>
         
                </div>
           
            </div>
        </>
    )
}

export default Allapiactive