import Statargeyservives from "../store/services/Userservices"
import { useDispatch, useSelector } from 'react-redux';
import * as actions from '../store/actions/Index'
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import { useRef } from "react";

const Invoicepag = () => {
	debugger
	const invcpost = useSelector(state => state.invcpost);
	const statrgychekoutreducer = useSelector(state => state.statrgychekoutreducer);
	const memabeshipopt = useSelector(state => state.memabeshipopt)
	const dispatch = useDispatch();
	const savedStrategyData = JSON.parse(localStorage.getItem('selectitmes'));
	const stgtotal = localStorage.getItem('invoiceamount')
	const granttotal = localStorage.getItem('invoiceamount')
	const chekoutinvoiceid = localStorage.getItem('invoiceid')
	let pursta = statrgychekoutreducer.slist
	 const cdata = savedStrategyData.map((value) => {
		return (
			<tr key={value.straId}>
				<td>{value.straId}</td>
				<td>{value.straName}</td>
				<td>{value.straTotalamt}.00</td>
			</tr>
		)

	})

    const history = useHistory()
    let showinvoive = () => {   
		let path = "/Dashboard";
		history.push(path);
}




const invoiceRef = useRef(); // Ref for the invoice container

const generatePDF = () => {
  const input = invoiceRef.current;
  html2canvas(input).then((canvas) => {
	const imgData = canvas.toDataURL("image/png");
	const pdf = new jsPDF("p", "mm", "a4"); // Portrait orientation, millimeters, A4 size
	const pdfWidth = pdf.internal.pageSize.getWidth();
	const pdfHeight = (canvas.height * pdfWidth) / canvas.width;
	pdf.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight);
	pdf.save("invoice.pdf"); // Save the PDF with the name 'invoice.pdf'

	      // Remove the class after generating the PDF
		  input.classList.remove("pdf-mode");
  });
};

	return (

		<div className="container" style={{marginTop:"100px"}} >

			<div className="invoice-container" ref={invoiceRef}>
				<div className="invoice-header text-center" style={{
					backgroundColor:"lightblue",
					color:"black",
					padding:"10px"
				}}>
					<h1>Invoice</h1>
					<h4>Invoice No: {chekoutinvoiceid}</h4>
					<p>Invoice Date: 20-12-2022</p>
				</div>

				<div className="invoice-details row py-5" style={{ marginTop: "5px" }}>
					<div className="col-md-6">
						<h5 style={{ fontWeight: "900" }}>Invoice To:</h5>
						<p>Name: Administrator</p>
						<p>User Name: administrator</p>
						<p>Email: administrator@gmail.com</p>
					</div>
					<div className="col-md-6">
						<h5>Payment Details:</h5>
						<p>Total Due: {granttotal}/-</p>
						<p>Payment Mode: Cashfree</p>
					</div>
				</div>

				<div className="invoice-purchases">
					<h5 style={{
						backgroundColor:"rosybrown",
						padding:"5px",
						color:"white"
						 
					}}>Membership Purchased</h5>
					<table className="table table-dark table-striped">
						<thead>
							<tr>
								<th>Id</th>
								<th>Type</th>
								<th>Amount</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td>{memabeshipopt.planid}</td>
								<td>{memabeshipopt.planname}</td>
								<td>{memabeshipopt.planamount}</td>
							</tr>
						</tbody>
					</table>

					<h5 style={{
						backgroundColor: "rosybrown",
						padding: "5px",
						color: "white"
					}}
					
					
					>Strategy Purchased</h5>
					<table className="table table-dark table-striped">
						<thead>
							<tr>
								<th>Id</th>
								<th>Type</th>
								<th>Amount</th>
							</tr>
						</thead>
						<tbody>
							{cdata}
						</tbody>
						<tfoot>
							<tr>
								<td>Total:</td>
								<td></td>
								<td>{stgtotal}.00</td>
							</tr>
						</tfoot>
					</table>
				</div>

				<div className="invoice-summary">
					<table className="table">
						<thead>
							<tr>
								<th>Particular</th>
								<th>Amount</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td>Plan Subtotal:</td>
								<td>{granttotal}.00</td>
							</tr>
							<tr>
								<td>Discount:</td>
								<td>000.00</td>
							</tr>
							<tr>
								<td>Sub Total:</td>
								<td>000.00</td>
							</tr>
							<tr>
								<td>Other Charges:</td>
								<td>000.00</td>
							</tr>
							<tr>
								<td>Total:</td>
								<td>000.00</td>
							</tr>
							<tr>
								<td>GST 18%:</td>
								<td>000.00</td>
							</tr>
							<tr>
								<td>Grand Total:</td>
								<td>{granttotal}.00</td>
							</tr>
						</tbody>
					</table>
					<button type="button" className="btn btn-primary no-print" onClick={showinvoive}>
						Close
					</button>
				</div>

				<div className="invoice-actions text-center mt-3">
					<button type="button" className="btn btn-success no-print" onClick={generatePDF}>
						Download PDF
					</button>
				</div>
			</div>
		</div>
	)


}


export default Invoicepag